import React from 'react';

import FontAwesome from 'react-fontawesome';

import { Button } from 'reactstrap';

import './CollegeSearchDropdown.scss';
import { lunrSearch } from '../../modules/lunr';

class CollegeSearchDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchOpen: false,
      search: '',
      selectedIndex: 0,
      searchResults: [],
      isFocused: false
    }

    this.searchBox = React.createRef();
  }

  timer = null;
  blurTimer = null;

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.blurTimer);
  }

  performSearch = (e) => {
    this.setState({ search: e.target.value, index: 0 }, () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        const { search } = this.state;
        let colleges = window.idx.map || [];

        const { result } = await lunrSearch('collegeIdx', search, colleges);
        this.setState({ searchResults: result, selectedIndex: 0 });
      })
    }, 150);
  }

  handleCollegeClick =  (e) => {
    this.props.onCollegeClick(e);
    this.setState({
      search: e.currentTarget.getAttribute('data-college-name'),
      selectedIndex: e.currentTarget.getAttribute('data-college-index')
    });
  }

  renderSearchResultCells() {
    const { searchResults, selectedIndex } = this.state;

    if (searchResults.length == 0) {
      return (
        <div className="search-cell" style={{ background: 'white' }}>
          No results found
        </div>
      )
    }

    return searchResults.map((item, i) =>  {
      return (
        <div id={`college-search-${i}`} data-college-index={i} data-college-id={item._id} data-college-name={item.displayName} onClick={this.handleCollegeClick} key={`search-cell-${i}`} className="search-cell" style={i == selectedIndex ? { borderLeft: '3px solid #0f629b' } : { borderLeft: '1px solid #ddd', paddingLeft: '10px' }}>
          <div className='name'>{item.displayName}</div>
          <div className='location'>{item.location}</div>
        </div>
      )
    });
  }

  handleKeyPress = (e) => {
    var { selectedIndex, searchResults } = this.state;

    switch (e.key) {
      case 'Enter':
        var firstResult = document.querySelector(`#college-search-${selectedIndex}`);
        if (firstResult) {
          // simulate an event here
          let e = {
            currentTarget: firstResult
          }

          this.props.onCollegeClick(e);
          this.setState({
            search: firstResult.getAttribute('data-college-name')
          })
        }
        break;
      case 'ArrowUp':
        if (selectedIndex > 0) {
          this.setState({ selectedIndex: selectedIndex - 1});
        }
        break;
      case 'ArrowDown':
        if (selectedIndex < searchResults.length && selectedIndex + 1 != searchResults.length) {
          this.setState({ selectedIndex: selectedIndex += 1});
        }
        break;
      default:
        break;
    }
  }

  toggleFocus = () => {
    const { isFocused } = this.state;

    if (isFocused) {
      // slight delay on blur to facilitate dropdown click
      clearTimeout(this.blurTimer);
      this.blurTimer = setTimeout(() => {
        this.setState({ isFocused: false });
      }, 150)

    } else {
      // immediate focus
      this.setState({ isFocused: !isFocused })
    }
  };

  handleClear = () => {
    this.props.onClear();
    this.setState({ search: '' });
  }

  render() {
    const { search, searchResults, isFocused } = this.state;

    return (
      <div className='CollegeSearchDropdown'>
        <input onFocus={this.toggleFocus} onBlur={this.toggleFocus} className='form-control' value={search} onKeyUp={this.handleKeyPress} onChange={this.performSearch} ref={this.searchBox} placeholder='Search for a college...' style={{ width: '100%' }} />
        {search && search.length > 0 && <Button color='link' onClick={this.handleClear} className='clear-icon' ><FontAwesome name='times' /></Button>}
        {search && search.length >= 3 && searchResults.length <= 25 && isFocused && <div className='search-results'>{this.renderSearchResultCells()}</div>}
      </div>
    )
  }
}

export default CollegeSearchDropdown;