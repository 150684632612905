import React, { useState, useCallback } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import './styles.scss';
import RequestDemoModal from '../RequestDemoModal/RequestDemoModal';

const WebinarSchedules = () => {
const [isOpen, setOpen] = useState(false);

const toggle = useCallback(() => {
  setOpen(!isOpen)
})

  return (
    <div className='WebinarSchedules bg-light px-3 text-center'>
      <div className='title'>
        Join our Weekly Webinar
      </div>
      <div>
        <Container>
          <Row>
            <Col xs='12'>
            <p style={{ fontSize: "1.5rem", textAlign: 'center' }} className='mb-5'>
          Each week the CollegeLab team will be giving demos for interested counselors to transition to CollegeLab. Our webinars will take place twice weekly starting on August 12th and take place on Zoom. To register please click the button below and select the session you would like to attend.
        </p>
        <Button size='lg' className='py-3 px-5' color='info' onClick={toggle}>
          Register for Webinar
        </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <RequestDemoModal isOpen={isOpen} toggle={toggle} />
    </div>
  )
}

export default WebinarSchedules;