import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';


import EndBlockCTA from '../../components/EndBlockCTA/EndBlockCTA';
import MetaUpdater from '../../components/MetaUpdater/MetaUpdater';

import './About.scss';

class About extends React.Component {
  renderFirstBlock() {
    return (
      <div className='first-block'>
        <Container>
          <Row className='banner'>
            <Col xs='12' style={{ fontWeight: 'bold', lineHeight: '1.22' }}>
              Your Artificially Intelligent College Admissions Advisor
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderOldSecondBlock() {
    return (
      <div className='second-block'>
        <Container style={{ color: 'black' }}>
          <Row>
            <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} className='title'>
              About Us
            </Col>
            <Col xs='12' sm='12' md={{ size: 10, offset: 1 }}>
              <Row className='align-items-center'>
                <Col xs='12' className='copy'>
                  <p>CollegeLab is an artificially intelligent college admissions adviser intended to help high school students and their parents better navigate the admissions process. Built using machine learning methods and the expertise of a former Ivy League Dean of Admissions, CollegeLab tells you your chances of getting into prospective colleges, highlighting the areas you should focus on to improve your chances.</p>
                  <p>CollegeLab was started by Jamison Feramisco, MD, PhD, a founder of multiple healthcare data and technology companies, who needed help during his son’s college application process. “When my son, as a ninth grader, asked me about how to prepare for the college process, I was embarrassed that I knew very little,” Feramisco admitted. He recruited several of his data science colleagues, researchers from Harvard, MIT, and Stanford, to build a tool to help with this process. After a year spent collecting data on more than four million applications with admissions outcomes, his team built a machine-learning algorithm that could evaluate applicants against historical school acceptance data and other student applications.</p>
                  <p>Feramisco and company connected with several former Ivy League admissions officers to test the algorithm against their own traditional assessments of a sample of student applications. He explained, “During the process of testing the algorithm against the admissions readers, the admissions team kept referring to application reading as an art. What we set out to do was apply science and data to that art. We were thrilled with the results and CollegeLab was born.”</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderSecondBlock() {
    return (
      <div className='second-block'>
        <Container style={{ color: 'black' }}>
          <Row>
            <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} className='title'>
              About Us
            </Col>
            <Col xs='12' sm='12' md={{ size: 10, offset: 1 }}>
              <Row className='align-items-center'>
                <Col xs='12' className='copy'>
                  <h3>CollegeLab at a Glance...</h3>
                  <p>CollegeLab is an artificially intelligent college admissions tool that helps families and counselors better navigate and manage the college admissions process. Built using machine learning methods and the expertise of a former Ivy League Dean of Admissions, CollegeLab utilizes over 4 million student applications with outcomes to tell students their chance of admission at 500 colleges and universities, comparing 12 data points and identifying areas they should focus on to improve your chances.</p>
                  <h3>Our Origins</h3>
                  <p>CollegeLab started when we were approached by a data scientist we had worked with on another project.  He informed us that, when doing his Masters in Data Science at Harvard, he had begun aggregating data and building an algorithm to figure out an individual’s chance of admission at various colleges.  The more we learned about the capabilities of his predictions and the motivations behind the tool – helping democratize the admissions process by providing greater insights to everyone regardless of their background or socioeconomic status – the greater our interest became. And so, wanting to make this tool available to students around the world to help them with the admission process, we set out to build a simple, intuitive, and powerful interface to allow students, their families, and counselors access to these predictions, around which we’ve build a suite of tools to better manage and navigate the admissions process.</p>
                  <p>Simultaneously, we were able to connect with several former Ivy League admissions officers, including a former Dean of Admissions, to help guide us in figuring out what admissions departments care about, and to test the algorithm against their own traditional assessments with a sample set of student applications.  Fortunately, we found that our algorithm agreed with the readers more than they agreed with each other, proving we had a statistically significant predictive tool, and thus, CollegeLab was born.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderThirdBlock() {
    return (
      <div className='third-block'>
        <Container>
          <Row>
            <Col xs='12' sm='12' md={{ offset: 1, size: 11 }}>
              <Row className='profile'>
                <Col xs={{ size: 12, order: 2 }} sm={{ size: 12, order: 2 }} md={{ size: 9, order: 2 }}>
                  <span className='name'>Jamal Sabky</span>
                  <p className='title'>CEO and Co-founder</p>
                  <p className='bio'>Co-Founder, Bema Holdings; Director, Bema Technologies; CEO and co-founder, Lutum Technologies; Dartmouth College</p>
                </Col>
                {/* <Col xs={{ size: 12, order: 1 }} sm={{ size: 12, order: 1 }} md={{ size: 3, order: 1 }}>
                  <img className='lozad img-fluid' alt='Aimahd Sabky' data-src='./images/aimahd_and_hobie.jpg' />
                </Col> */}
              </Row>
            </Col>
            <Col xs='12' sm='12' md={{ offset: 1, size: 11 }}>
              <Row className='profile'>
                <Col xs={{ size: 12, order: 2 }} sm={{ size: 12, order: 2 }} md={{ size: 9, order: 1 }}>
                  <span className='name'>Aimahd Sabky</span>
                  <p className='title'>COO and Co-founder</p>
                  <p className='bio'>Co-Founder and COO Bema Technologies; Amherst College</p>
                </Col>
                {/* <Col xs={{ size: 12, order: 1 }} sm={{ size: 12, order: 1 }} md={{ size: 3, order: 2 }}>
                  <img className='lozad img-fluid' alt='Aimahd Sabky' data-src='./images/aimahd_and_hobie.jpg' />
                </Col> */}
              </Row>
            </Col>
            <Col xs='12' sm='12' md={{ offset: 1, size: 11 }}>
              <Row className='profile'>
                <Col xs={{ size: 12, order: 2 }} sm={{ size: 12, order: 2 }} md={{ size: 9, order: 2 }}>
                  <span className='name'>Omer Trajman</span>
                  <p className='title'>CTO</p>
                  <p className='bio'>Technology Advisor, Bema Technologies; Founder Askfora; Former CEO Rocana; Former VP Technology Solutions Cloudera; Board Member Tufts Entrepreneurship Center</p>
                </Col>
                {/* <Col xs={{ size: 12, order: 1 }} sm={{ size: 12, order: 1 }} md={{ size: 3, order: 1 }}>
                  <img className='lozad img-fluid' alt='Aimahd Sabky' data-src='./images/aimahd_and_hobie.jpg' />
                </Col> */}
              </Row>
            </Col>
            <Col xs='12' sm='12' md={{ offset: 1, size: 11 }}>
              <Row className='profile'>
                {/* <Col xs={{ size: 12, order: 1 }} sm={{ size: 12, order: 1 }} md={{ size: 3, order: 1 }}>
                  <img className='lozad img-fluid' alt='Ayin Vala' data-src='./images/vala.jpg' />
                </Col> */}
                <Col xs={{ size: 12, order: 2 }} sm={{ size: 12, order: 2 }} md={{ size: 9, order: 2 }}>
                  <span className='name'>Ayin Vala</span>
                  <p className='title'>Data Science</p>
                  <p className='bio'>AI entrepreneur; Launch Lab X at Harvard Business School; Master’s degree in Information Systems from Harvard University and resides in San Francisco</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderFourthBlock() {
    return(
      <div className="fourth-block">
        <div className="header">
        CollegeLab was developed under Bema Technologies
        </div>
        <div className="sub-head">
        Bema is an investment and incubation platform for early-stage companies
        </div>
        <Button className="learn-more-button" href="https://bema.dev/" target="_blank">
          Learn More
        </Button>
      </div>
    );
  }


  render() {
    return (
      <div className='About'>
        <MetaUpdater
          title='College Admissions | College Counseling | CollegeLab'
          description='CollegeLab Helps You Identify Reach, Target, and Likely Colleges. Get the Guidance You Need to Succeed in College Admissions and Get Into Top Choice Universities.' />
        {this.renderFirstBlock()}
        {this.renderSecondBlock()}
        {this.renderThirdBlock()}
        {this.renderFourthBlock()}
        <EndBlockCTA />
      </div>
    );
  }
}

export default About;
