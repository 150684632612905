/* eslint-disable max-len */
import React from 'react';
import { Row, Col, Input, Button, Container, Table } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect } from 'react-router-dom';

import Featured from './Featured';
import PostList from './PostList';
import SearchList from './SearchList';

import './Blog.scss';
import EndBlockCTA from '../../components/EndBlockCTA/EndBlockCTA';


const cleanString = (input) => {
  var output = "";
  for (var i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      output += input.charAt(i);
    } else if(input.charCodeAt(i) == 8217) {
      output += "'";
    } else {
      output += " ";
    }
  }
  return output;
}

class Blog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      articles: []
    }
  }

  async componentDidMount() {
    const url = "/medium/feed/collegelab";
    const response = await fetch(url);
    const data = await response.text();

    let parsed = (new window.DOMParser()).parseFromString(data, 'text/xml');

    let articles = []

    let items = parsed.getElementsByTagName('item');

    for (let item of items) {

      const title = cleanString(item.getElementsByTagName("title")[0].textContent);
      const html = item.getElementsByTagName("content:encoded")[0].textContent;
      const regex = /(<([^>]+)>)/ig;
      
      let noTagHtml = cleanString(html.replace(regex, " "));

      if (noTagHtml.includes(title)) {
        noTagHtml = noTagHtml.replace(title, "");
      }

      articles.push({
        title,
        date: item.getElementsByTagName("pubDate")[0].textContent.split(' ').slice(0, 4).join(' '),
        body: noTagHtml.split(' ').slice(0, 50).join(' ') + "...",
        link: item.getElementsByTagName("link")[0].textContent
      })
    }

    this.setState({
      articles,
      loading: false
    })
  }


  render() {
    if (this.props.match.params.page < 1) {
      return <Redirect to='/blog/1' />
    }

    return (
      <div>
        <Container className="Blog">
          <div className='title'>CollegeLab Blog</div>
          <div style={{ fontSize: '20px', fontWeight: '500', paddingBottom: '36px' }}>Helping high school students to navigate the admissions process.</div>

          {this.state.articles.map((article, i) => <DisplayBlogPosts {...article} key={`article-${i}`} />)}

        </Container>
        <EndBlockCTA />
      </div>
    );
  }
}

export default Blog;

const DisplayBlogPosts = ({ title, date, body, link }) => {
  return (
    <>
      <div className="blog-title">
        {title}
      </div>
      <div className="blog-date-line">
        {date}
      </div>
      <div className="blog-body">
        {body}
      </div>
      <div className="blog-link">
        <a href={link} target="_blank">Click to read more...</a>
      </div>
    </>
  )
}