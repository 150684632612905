import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav, NavItem, NavLink, NavbarToggler, Collapse, Container, NavbarBrand, Navbar, Row, Col, Button } from 'reactstrap';
import $ from 'jquery';
import FontAwesome from 'react-fontawesome';

import settings from '../../settings';

import SignupModal from '../SignupModal/SignupModal';
import ScrollNav from './ScrollNav';

import './Navigation.scss';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this)
    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      dropdownOpen: false,
      collapsed: true,
      isMobileMenuOpen: false,
      width: 0,
      modal: false,
      isOpen: false,
    };
  }

  toggleMobileMenu() {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen
    });
  }

  toggle() {
    this.state.dropdownOpen ? $("#navbar-id").css("border-bottom", "1px solid white") : $("#navbar-id").css("border-bottom", "1px solid black");

    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    }, () => {
      if (!this.state.modal) {
        this.toggleMobileMenu();
      }
    });
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  updateDimensions() {
    this.setState({ width: $(window).width() });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  renderBlueNav() {
    return (
      <div className='blue-nav'>
        <ScrollNav toggle={this.toggleModal} heightToScrollAt={500} />
        <Navbar expand="md" id="navbar-id">
          <Container fluid={this.state.width < 1200 ? true : false}>
            <NavbarBrand>
              <LinkContainer to="/">
                <Row className='align-items-center'>
                  <Col xs='4'>
                    <img height='60px' src='/images/logo-negative-flat.svg' alt='logo' />
                  </Col>
                </Row>
              </LinkContainer>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} />
            <Collapse isOpen={!this.state.collapsed} navbar>
              <Nav navbar className='ml-auto'>
                <NavItem>
                  <LinkContainer to="/counselors">
                    <NavLink>Counselors</NavLink>
                  </LinkContainer>
                </NavItem>
                <NavItem>
                  <LinkContainer to="/about">
                    <NavLink>About</NavLink>
                  </LinkContainer>
                </NavItem>
                <NavItem>
                  <NavLink href={"https://blog.collegelab.org/"}>Blog</NavLink>
                </NavItem>
                <NavItem>
                  <a className='nav-link' href={settings.APP_URL + "/login"}>
                    Sign In
                  </a>
                </NavItem>
                <NavItem className='nav-link' onClick={this.toggleModal} style={{ marginRight: '0px', paddingRight: '0px' }}>
                  <span className='get-started'>Get Started</span>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }

  renderWhiteNav() {
    return (
      <div className='white-nav'>
        <Navbar expand="md" id="navbar-id">
          <Container fluid={this.state.width < 1200 ? true : false}>
            <NavbarBrand>
              <LinkContainer to="/">
                <Row className='align-items-center'>
                  <Col xs='4'>
                    <img height='60px' src='/images/logo-flat.svg' alt='logo' />
                  </Col>
                </Row>
              </LinkContainer>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} />
            <Collapse isOpen={!this.state.collapsed} navbar>
              <Nav navbar className='ml-auto'>
                <NavItem>
                  <LinkContainer to="/counselors">
                    <NavLink>Counselors</NavLink>
                  </LinkContainer>
                </NavItem>
                <NavItem>
                  <LinkContainer to="/about">
                    <NavLink>About</NavLink>
                  </LinkContainer>
                </NavItem>
                <NavItem>
                  <NavLink href={"https://blog.collegelab.org/"}>Blog</NavLink>
                </NavItem>
                <NavItem>
                  <a className='nav-link' href={settings.APP_URL + "/login"}>
                    Sign In
                  </a>
                </NavItem>
                <NavItem onClick={this.toggleModal} className='nav-link' style={{ marginRight: '0px', paddingRight: '0px' }}>
                  <span className='get-started'>Get Started</span>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }

  renderMobileMenu(path) {
    var isBlue = (path === '/' || path === '/about' || path === '/counselors' || path === '/pricing') && !this.state.isMobileMenuOpen;

    return (
      <div>
        <Container className='mobile-nav' style={isBlue ? { background: '#11629a' } : { background: 'white' }} fluid>
          <Row className='align-items-center'>
            <Col xs='auto'>
              <LinkContainer onClick={() => this.setState({ isMobileMenuOpen: false })} to="/" style={{ marginLeft: '5px' }}>
                <img alt='CollegeLab' src={`/images/logo-${isBlue ? 'negative-' : ''}flat.svg`} height='36px' />
              </LinkContainer>
            </Col>
            <Col xs='auto' className='ml-auto'>
              <FontAwesome name={this.state.isMobileMenuOpen ? 'times' : 'bars'} size='lg' style={isBlue ? { color: 'white', paddingRight: '5px' } : { color: '#3b4145', paddingRight: '5px' }} onClick={this.toggleMobileMenu} />
            </Col>
          </Row>
        </Container>
        <div hidden={!this.state.isMobileMenuOpen} className="public-mobile-nav">
          <LinkContainer className="menu-item" to="/counselors">
            <NavLink onClick={this.toggleMobileMenu}>Counselors</NavLink>
          </LinkContainer>
          <LinkContainer className="menu-item" to="/about">
            <NavLink onClick={this.toggleMobileMenu}>About</NavLink>
          </LinkContainer>
          <a className="nav-link menu-item" href="https://blog.collegelab.org/">
            <NavLink>Blog</NavLink>
          </a>
          <a className="nav-link menu-item" href={settings.APP_URL + "/login"} onClick={this.toggleMobileMenu}>Sign in</a>
          <Button style={{ fontSize: '18px', padding: '15px 46px', marginTop: '12px' }} onClick={this.toggleModal} color='primary'>Get Started</Button>
        </div>
      </div>
    );
  }

  renderMenu() {
    if (this.state.width <= 768) {
      return (this.renderMobileMenu(window.location.pathname));
    } else if (window.location.pathname === '/' || window.location.pathname === '/about' || window.location.pathname === '/counselors' || window.location.pathname === '/pricing') {
      return (this.renderBlueNav());
    } else {
      return (this.renderWhiteNav());
    }
  }

  render() {
    return (
      <div>
        {this.renderMenu()}
        <SignupModal isOpen={this.state.modal} toggle={this.toggleModal} />
      </div>
    )
  }
}



export default Navigation;
