/* eslint-disable max-len */
import React from 'react';
import { Row, Col } from 'reactstrap';

import moment from 'moment';

import './Blog.scss';

class SearchList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      loading: true
    }
  }

  componentDidMount() {
    if (this.props.search.length > 0) {
      runSearch(this.props.search).then(res => {
        this.setState({
          posts: res.posts,
          loading: false
        });
      });
    }
  }
  
  renderPosts() {
    const { posts, loading } = this.state;
    const { search } = this.props;

    if (loading) {
      return null;
    }

    if (posts.length === 0) {
      return (
        <Col xs='12'>
          No posts found for search result <strong><i>{search}</i></strong>
        </Col>
      )
    }

    return posts.map((post, index) => {
      return (
        <Col xs='12' key={'post-cell-' + index} className='post-wrapper'>
          <div className='post-cell' style={ index === 0 ? { paddingTop: '0px', borderTop: 'none' } : {}}>
            <Row>
              <Col xs='12' sm='12' md='8'>
                <div style={{ fontSize: '24px', fontWeight: 'bold', paddingBottom: '12px' }}>{post.title}</div>
                <div style={{ color: '#3b4145', paddingBottom: '16px' }}>{post.contentPreview}</div>
                <div style={{ fontWeight: 'bold' }}>{post.author}</div>
                <div style={{ paddingBottom: '12px' }}>{moment(post.date).format('LL') + ' · ' + post.readTime}</div>
                <div><a href={'/blog/articles/' + post.url}>Read More</a></div>
              </Col>
              <Col xs='4' className='postlist-image'>
                <img src={post.thumbnailImage} alt={post.title} className='img-fluid' />
              </Col>
            </Row>
          </div>
        </Col>
      );  
    })
  }

  render() {
    return (
      <Row className="SearchList">
        {this.renderPosts()}
      </Row>
    );
  }
}

export default SearchList;

const runSearch = async (search) => {
  const response = await fetch('/api/v1/posts/search?search=' + search, {
    method: "GET"
  }).then(response => {
    return response
  }, function(error) {
    console.log(error);
  });

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);

  return body;
}
