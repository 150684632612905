import React from 'react';

import { Alert, Row, Col, Container } from 'reactstrap';

import MetaUpdater from '../../components/MetaUpdater/MetaUpdater';


const NotFound = () => (
  <Container>
  <MetaUpdater title='404 Not Found' description='There is nothing here.' />
    <Row>
      <Col style={{ paddingTop: '24px' }}>
        <Alert color="danger" style={{ padding: '24px 15px' }}>
          <strong>Error [404]</strong>: {window.location.pathname} does not exist.
        </Alert>
      </Col>
    </Row>
  </Container>
);

export default NotFound;
