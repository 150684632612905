import React from 'react';
import { Container, Row, Col, FormGroup, Label, Button, Alert, Input, InputGroup, InputGroupAddon, FormFeedback } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import salert from '../../modules/salert';

import { withRouter } from 'react-router-dom';

import AccountPageFooter from '../../components/AccountPageFooter/AccountPageFooter';
import Plans from '../../components/Plans/Plans';

import FontAwesome from 'react-fontawesome';
import AnimateHeight from 'react-animate-height';

import settings from '../../settings';

import ReCAPTCHA from "react-google-recaptcha";

import './IndividualSignup.scss'
import MetaUpdater from '../../components/MetaUpdater/MetaUpdater';

import qs from 'qs';

var SHA256 = require("crypto-js/sha256");

const WAIT_INTERVAL = 500;

class IndividualSignup extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.recaptchaRef = React.createRef();

    this.state = {
      couponState: "none",
      price: 0,
      totalCost: -1,
      isFreeCoupon: false,
      isFreeTrial: false,
      consent: false,
      euroConsent: false,
      coupon: '',
    }
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.fbq('track', 'InitiateCheckout');
    }

    document.getElementById('content-main').classList.add('content-grey-border');

    const { token } = this.props.match.params;

    if (!token) {
      return;
    }

    postAffiliate({ affiliate: token }).then(res => {
      if (res.valid) {
        let referralData = {
          token,
          params: window.location.search ? qs.parse(window.location.search, { ignoreQueryPrefix: true }) : {},
          discount: null
        }

        if (res.coupon) {
          referralData.coupon = res.coupon;

          clearTimeout(this.timer);
          this.setState({ coupon: res.coupon, couponState: 'loading' }, () => {
            this.timer = setTimeout(() => {
              this.checkCoupon(res.coupon.toLowerCase())
            }, WAIT_INTERVAL);
          })
        }

        sessionStorage.setItem('referralData', JSON.stringify(referralData));
      } else {
        this.props.history.push("/signup")
      }
    });
  }

  componentWillUnmount() {
    document.getElementById('content-main').classList.remove('content-grey-border');
    this.timer = null;
  }

  handleSubmit(values, setSubmitting) {
    let referralData = sessionStorage.getItem('referralData') ? JSON.parse(sessionStorage.getItem('referralData')) : {};
    postSignup({
      user: {
        email: values.email,
        password: {
          digest: SHA256(values.password).toString(),
          algorithm: "sha-256"
        },
        profile: {
          name: {
            first: values.firstName,
            last: values.lastName
          }
        },
        referralData
      }
    }).then(res => {
      console.log(res);
      if (res.err) {
        setSubmitting(false);
        salert('error', res.err);
        return
      } else {
        window.location.replace(settings.APP_URL + '/login/' + res.token);
      }
    });
  }

  renderSignupForm(props) {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit
    } = props;

    return (
      <form id='student-payment' onSubmit={(e) => {
        e.preventDefault();

        if (process.env.NODE_ENV === 'production') this.recaptchaRef.current.execute();

        handleSubmit();
      }}>
        <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="firstNameLabel">First Name</Label>
              <Input
                name="firstName"
                type="text"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors.firstName && touched.firstName}
                placeholder='First Name'
              />
              {errors.firstName &&
                touched.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="lastNameLabel">Last Name</Label>
              <Input
                name="lastName"
                type="text"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors.lastName && touched.lastName}
                placeholder='Last Name'
              />
              {errors.lastName &&
                touched.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label for="emailAddressLabel">Email Address</Label>
              <Input
                name="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors.email && touched.email}
                placeholder='Email Address'
              />
              {errors.email &&
                touched.email && <FormFeedback>{errors.email}</FormFeedback>}
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label for="passwordLabel">Password</Label>
              <Input
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors.password && touched.password}
                placeholder='Password'
              />
              {errors.password &&
                touched.password && <FormFeedback>{errors.password}</FormFeedback>}
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Input
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={errors.confirmPassword && touched.confirmPassword}
                placeholder='Confirm Password'
              />
              {errors.confirmPassword &&
                touched.confirmPassword && <FormFeedback>{errors.confirmPassword}</FormFeedback>}
            </FormGroup>
          </Col>
          <Col xs='12' className='tos-statement'>
            <FormGroup check >
              <Label check className='custom-control custom-checkbox'>
                <Input type="checkbox" checked={this.state.consent} onClick={() => this.setState({ consent: !this.state.consent })} className='custom-control-input' />{' '}
                <span className="custom-control-indicator" ></span>
                <span className="custom-control-description" style={{ cursor: "pointer", fontWeight: 'normal' }}>I have <strong>read</strong> and <strong>agree</strong> to CollegeLab's <a href='https://app.collegelab.org/terms' target='_blank' rel="noopener noreferrer">Terms of Service</a> and <a to='https://app.collegelab.org/privacy' target='_blank' rel="noopener noreferrer">Privacy Policy.</a></span>
              </Label>
            </FormGroup>
            <FormGroup check >
              <Label check className='custom-control custom-checkbox'>
                <Input type="checkbox" checked={this.state.euroConsent} onClick={() => this.setState({ euroConsent: !this.state.euroConsent })} className='custom-control-input' />{' '}
                <span className="custom-control-indicator" ></span>
                <span className="custom-control-description" style={{ cursor: "pointer", fontWeight: 'normal' }}>I consent that I am <strong>16 years of age</strong> or older or have my guardian's consent and am <strong>not</strong> a citizen of the European Union.</span>
              </Label>
            </FormGroup>
          </Col>
          <Col xs="12">
            <div className="align-center">
              {isSubmitting ? <Button className="btn-info" disabled={true}><FontAwesome name='spinner' spin /></Button> : <Button type="submit" className="btn-info" disabled={!this.state.consent || !this.state.euroConsent}>Sign Up</Button>}
            </div>
          </Col>
          <Col xs='12' style={{ paddingTop: '16px', textAlign: 'center' }}>
            {process.env.NODE_ENV === 'production' &&
              <ReCAPTCHA
                ref={this.recaptchaRef}
                size="invisible"
                badge="inline"
                sitekey={settings.RECAPTCHA}
              />
            }
            <div>
              <small>This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
              </small>
            </div>
          </Col>
        </Row>
      </form>
    )
  }

  render() {
    const { token } = this.props.match.params;
    let isCollegeConfidential = token && token == 'college-confidential';

    return (
      <Container className="IndividualSignup">
        <MetaUpdater
          title='College Admissions Guidance | College Counseling | CollegeLab'
          description='CollegeLab Helps You Identify Best Fit Colleges. Get the Guidance You Need to Succeed in College Admissions and Get Into Top Choice Universities.' />
        <Row>
          <Col xs="12" sm='12' md={{ size: 10, offset: 1 }}>
            <h4>Get Started</h4>
            <Row className="signup-box">
              {/* <Col md={{ size: 2, offset: 2 }} style={{ textAlign: 'center' }}>  
                <img src='/images/mobile-logo.svg' width='100%' />
              </Col> */}
              {/* <Col md={{ size: 6 }}> */}
              <Col md={{ size: 8, offset: 2 }}>
                <Alert color="info" className="alert" style={{ marginBottom: '24px' }}>
                  Sign up <strong>now</strong> to start your CollegeLab experience! <strong>Find schools</strong> that are the best fit for you, <strong>improve your chance</strong> of acceptance, <strong>save time and money</strong> by curating a well-balanced list.
                </Alert>
                {/* </Col> */}
                <Formik
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: ''
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    this.handleSubmit(values, setSubmitting);
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required('Required'),
                    lastName: Yup.string().required('Required'),
                    email: Yup.string().email().required('Required'),
                    password: Yup.string().required('Required').test('len', 'Must be at least 6 characters', val => (val === undefined || (val && val.length >= 6))),
                    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Your passwords do not match')
                  })}
                >
                  {props => this.renderSignupForm(props)}
                </Formik>
              </Col>
            </Row>
            <AccountPageFooter>
              <p>{'Already have an account?'}<Button href={settings.APP_URL + '/login'} className="btn-outline-info">Log in</Button></p>
            </AccountPageFooter>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(IndividualSignup);

const checkCoupon = async (coupon) => {
  const response = await fetch('/api/v1/coupons/check?coupon=' + coupon, {
    method: "GET"
  }).then(response => {
    return response
  }, function (error) {
    console.log(error);
  });

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);

  return body;
}

const postSignup = async (user) => {
  const response = await fetch('/api/v1/individual-signup', {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response
  }, function (error) {
    console.log(error);
  });

  const body = await response.json();
  return body;
}

const postAffiliate = async (affiliate) => {
  const response = await fetch('/api/v1/check-affiliate', {
    method: "POST",
    body: JSON.stringify(affiliate),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response
  }, function (error) {
    console.log(error);
  });

  const body = await response.json();
  return body;
}