import React from 'react';
import {
  Row, Col, Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

class MarketingModal extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size='lg'>
        <ModalHeader toggle={this.props.toggle}>
          <Row className="align-items-center">
            <Col className="text-center" xs="12" sm="12" md='12' lg='12'>
              CollegeLab is a counseling service backed by artificial intelligence and expert admissions insights focused on improving your chances of college acceptance.
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <Row className="align-items-center">
            <Col className="text-center" xs="12" sm="12" md='12' lg='12'>
              <h4><b>What best describes your immediate college admissions goals?</b></h4>
            </Col>
          </Row>
          <Row>
            <Col xs="12" style={{ marginBottom: '15px' }}>
              <LinkContainer to="/signup">
                <Button block size="lg" color="info" onClick={this.props.toggle}>
                  I'm a student who wants to calculate my chances of acceptance
                </Button>
              </LinkContainer>
            </Col>
            <Col xs="12" style={{ marginBottom: '15px' }}>
              <Button href={`https://app.collegelab.org/counselor-signup`} block size="lg" color="info" onClick={this.props.toggle}>
                I'm a college counselor looking for analytics and management tools
              </Button>
            </Col>
            <Col xs="12">
              <Button href={"https://blog.collegelab.org/"} block size="lg" color="info" onClick={this.props.toggle}>
                I'm interested in reading more about the college application and <br /> admissions process
              </Button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="text-center" xs="12" sm="12" md='12' lg='12'>
              <img className='lozad img-fluid' src="/images/marketing-modal-image.jpg" alt="Joyce Ivy" />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default MarketingModal;