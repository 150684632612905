import React from 'react';

import FontAwesome from 'react-fontawesome';
import Lightbox from 'react-image-lightbox';
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import MetaUpdater from '../../components/MetaUpdater/MetaUpdater';

import RequestDemoModal from '../../components/RequestDemoModal/RequestDemoModal';
import WebinarSchedules from '../../components/WebinarSchedules/WebinarSchedules';
import './Counselors.scss';

const images = [
  '/images/dashboard1.png',
  '/images/student-panel.png',
  '/images/counselor-panel.png',
  '/images/student-profile1.png',
  '/images/student-profile2.png',
  '/images/chat.png'
]

class Counselors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isOpen: false,
      photoIndex: 0,
      isDemoModalOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleDemoModal = () => this.setState({ isDemoModalOpen: !this.state.isDemoModalOpen });

  renderFirstBlock() {
    return (
      <div className='first-block'>
        <Container style={{ color: 'white' }}>
          <Row>
            <Col xs='12' className='title'>
              <span className='big-text'>CollegeLab for Counselors</span>
            </Col>
            <Col xs='12' className='subtitle'>
              Greater Analytics and Powerful Management Tools for a Fraction of the Cost.
            </Col>
            {/* <Col xs='12' sm='12' md={{ size: '8', offset: '2' }} lg={{ size: '6', offset: '3' }} style={{ fontSize: '20px' }}>
              <Button block color='primary' href='/guided-path-transition-offer' style={{ padding: '15px 46px', marginTop: '12px', textDecoration: 'none !important', fontSize: '25px'}} >GuidedPath Special Offer!</Button>
            </Col> */}
            <Col xs='12' sm='12' md={{ size: '8', offset: '2' }} lg={{ size: '4', offset: '4' }} style={{ fontSize: '20px' }}>
              <Button block onClick={this.toggleDemoModal} color='secondary' outline style={{ padding: '15px 46px', marginTop: '60px' }}>Request a Demo</Button>
              <Button block color='primary' href='https://app.collegelab.org/counselor-signup' style={{ padding: '15px 46px', marginTop: '12px', textDecoration: 'none !important' }} >Sign Up Now<FontAwesome name='flask' style={{ marginLeft: '8px' }} /></Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }


  renderFeaturesBlock() {
    return (
      <div className='features-block'>
        <Container fluid style={{ color: 'black' }}>
          <Row>
            <Col xs='12' className='features'>
              Counseling Features
            </Col>
            <Col xs='12' sm='12' md='4'>
              <div className='title'>Dashboard</div>
              <img className='img-fluid lozad' alt='dashboard' data-src='/images/dashboard1.png' onClick={() => this.setState({ isOpen: true, photoIndex: 0 })} />
              <div className='copy'>
                Unread chats, upcoming meetings, and important bookmarks all at your fingertips.
              </div>
            </Col>
            <Col xs='12' sm='12' md='4'>
              <div className='title'>Student Management</div>
              <img className='img-fluid lozad' alt='student-panel' data-src='/images/student-panel.png' onClick={() => this.setState({ isOpen: true, photoIndex: 1 })} />
              <div className='copy'>View and manage your students, invite new students, assign them to counselors, schedule meetings, and more.</div>
            </Col>
            <Col xs='12' sm='12' md='4'>
              <div className='title'>Counselor Management</div>
              <img className='img-fluid lozad' alt='counselor-panel' data-src='/images/counselor-panel.png' onClick={() => this.setState({ isOpen: true, photoIndex: 2 })} />
              <div className='copy'>Invite and manage counselors, assign roles based on counselor responsibilities.</div>
            </Col>
          </Row>
          <Row style={{ paddingTop: '42px' }}>
            <Col xs='12' sm='12' md='4'>
              <div className='title'>Student Profiles</div>
              <img className='img-fluid lozad' alt='student-profile' data-src='/images/student-profile1.png' onClick={() => this.setState({ isOpen: true, photoIndex: 3 })} />
              <div className='copy'>View student information at a glance, add notes to profile fields, create additional fields specific to each student, schedule appointments, and view and annotate previous meetings.</div>
            </Col>
            <Col xs='12' sm='12' md='4'>
              <div className='title'>College List Builder</div>
              <img className='img-fluid lozad' alt='student-profile-alt' data-src='/images/student-profile2.png' onClick={() => this.setState({ isOpen: true, photoIndex: 4 })} />
              <div className='copy'>View a student’s academic information and college list quickly and easily, recommend or advise against schools.</div>
            </Col>
            <Col xs='12' sm='12' md='4'>
              <div className='title'>In-App Chat</div>
              <img className='img-fluid lozad' alt='chat' data-src='/images/chat.png' onClick={() => this.setState({ isOpen: true, photoIndex: 5 })} />
              <div className='copy'>Chat with students about specific schools and track their application status.</div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderPricingBlock() {
    return (
      <div className='features-block bg-light' style={{ backgroundImage: "none" }}>
        <Container style={{ color: 'black' }}>
          <Row>
            <Col xs='12' className='features text-center'>
              Pricing Plans
            </Col>
            <Col xs='12' sm='12' md='4'>
              <Card>
                <CardBody>
                <CardTitle>
                    <strong>Starter Plan</strong>
                  </CardTitle>
                  <CardTitle>
                    $29/month
                  </CardTitle>
                  <CardText>
                    10 students. Unlimited counselors. Our cheapest pricing plan for counselors.
                    </CardText>
                    <Button href="https://app.collegelab.org/counselor-signup" color="info" size='lg'>
                      Get Started
                    </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs='12' sm='12' md='4'>
              <Card>
                <CardBody>
                  <CardTitle>
                    <strong>Premium Plan</strong>
                  </CardTitle>
                  <CardTitle>
                    $49/month
                  </CardTitle>
                  <CardText>
                    50 students. Unlimited counselors. Go premium and manage your business.
                    </CardText>
                    <Button href="https://app.collegelab.org/counselor-signup" color="info" size='lg'>
                      Get Started
                    </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs='12' sm='12' md='4'>
              <Card>
                <CardBody>
                <CardTitle>
                    <strong>Pro Plan</strong>
                  </CardTitle>
                  <CardTitle>
                    $79/month
                  </CardTitle>
                  <CardText>
                    Unlimited students. Unlimited counselors. Get CollegeLab for your entire organization.
                    </CardText>
                    <Button href="https://app.collegelab.org/counselor-signup" color="info" size='lg'>
                      Get Started
                    </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderCopyBlock() {
    return (
      <div className='CopyBlock' >
        <Container>
          <Row>
            <Col xs='12' sm='12' md='12' lg='4' className='content'>
              <FontAwesome name='chart-pie' size='3x' />
              <h4>Better Outcomes</h4>
              <p>Chat with students, track progress, view scores and college list, and recommend schools to make sure your students are on the right track every step of the way. Search and sort schools by multiple criteria to find the right match for your students.</p>
            </Col>
            <Col xs='12' sm='12' md='12' lg='4' className='content'>
              <FontAwesome name='clock' size='3x' />
              <h4>Save Time</h4>
              <p>CollegeLab allows you to manage your students with an intuitive, scalable, and powerful interface. Schedule meetings and reference or annotate past meetings, record and store notes and to-do lists.</p>
            </Col>
            <Col xs='12' sm='12' md='12' lg='4' className='content'>
              <FontAwesome name='hand-holding-usd' size='3x' />
              <h4>Save Money</h4>
              <p>Supports experienced counselors and jump starts new counselors.  For independent counselors, CollegeLab is a high value addition with an opportunity to upcharge. For schools, get great analytics and powerful management tools at a fraction of the cost.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderCounselorEndCTA() {
    return (
      <Container className='CounselorEndBlockCTA'>
        <Row>
          <Col xs='12' className='title inside-cta'>
            <div className='title'>Get the Power of CollegeLab for Counselors Today!</div>
            {/* <div style={{ paddingTop: '24px', fontWeight: 'normal', fontSize: '24px', color: '#3b4145' }}>Starting at <Link to="/pricing"><strong>$29/month</strong></Link></div> */}
            <Row>
              <Col xs='12' sm='12' md='12' lg='12' style={{ paddingTop: '36px' }}>
                <Button color='primary' style={{ padding: '14px 36px' }} href='https://app.collegelab.org/counselor-signup'><span style={{ fontSize: '1.5rem' }}>Get Started</span></Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalBody>
            <Row>
              <Col xs='12' style={{ paddingBottom: '15px' }}>
                <Button block size='lg' color='primary' href='https://app.collegelab.org/counselor-signup' onClick={this.toggle}>Counselor</Button>
              </Col>
              <Col xs='12'>
                <Button block size='lg' color='info' href='https://www.collegelab.org/signup' onClick={this.toggle}>Student/Parent</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Container>
    );
  }

  render() {
    return (
      <div className='Counselors'>
        <MetaUpdater
          title='College Consultants | College Counselors | CollegeLab'
          description='Manage Your Students, Counselors, and Time with CollegeLab’s Powerful Counselor Tools. Join our IEC Community and Grow Your Counseling Business with CollegeLab.' />
        {this.renderFirstBlock()}
        {/* {this.renderPricingBlock()} */}
        {this.renderFeaturesBlock()}
        <WebinarSchedules />
        {this.renderCopyBlock()}
        {this.renderCounselorEndCTA()}

        <RequestDemoModal isOpen={this.state.isDemoModalOpen} toggle={this.toggleDemoModal} />

        {this.state.isOpen && (
          <Lightbox
            mainSrc={images[this.state.photoIndex]}
            nextSrc={images[(this.state.photoIndex + 1) % images.length]}
            prevSrc={images[(this.state.photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default Counselors;
