import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import moment from 'moment';

import './Report.scss';

class GenerateReport extends React.Component {
  state = {
    params: {},
    safety: [],
    probable: [],
    reach:[]
  }

  timer = null;
  reportRef = React.createRef();

  runningPageHeight = 0;

  componentDidMount() {
    this.refreshInterval();
  }

  componentDidUpdate() {
    this.hideNavAndFooter();
  }

  componentWillUnmount() {
    clearImmediate(this.timer)
  }

  refreshInterval = () => {
    this.timer = setInterval(() => {
      this.hideNavAndFooter();
      this.setState({
        params: window.params
      }, () => {
        this.setSchoolLevels();
        if (window.params) {
          clearInterval(this.timer);
          setTimeout(() => {
            this.setState({
              params: window.params
            });
          }, 150);
        }
      });
    }, 150)
  }

  setSchoolLevels = () => {
    const schoolData = {
      safety: [],
      probable: [],
      reach: [],
    };
  
    if (window.params) {
      if (window.params.schoolInformation) {
        window.params.schoolInformation.forEach((school) => {  
          if (school.chanceOfAdmission < 25) {
            schoolData.reach.push(school);
          } else if (school.chanceOfAdmission < 75) {
            schoolData.probable.push(school);
          } else {
            schoolData.safety.push(school);
          }
        })
      }
    }

    this.setState({
      safety: schoolData.safety,
      probable: schoolData.probable,
      reach: schoolData.reach
    })
  }

  hideNavAndFooter = () => {
    let topNav = document.getElementsByClassName('white-nav')
    let i = 0;
    if (topNav && topNav.length > 0) {
      for (i = 0; i < topNav.length; i++) {
        topNav[i].style.height = 0;
        topNav[i].style.visibility = 'hidden';
        topNav[i].style.padding = 0;
        topNav[i].style.margin = 0;
      };
    }

    let mobileNav = document.getElementsByClassName('mobile-nav')
    if (mobileNav && mobileNav.length > 0) {
      for (i = 0; i < mobileNav.length; i++) {
        mobileNav[i].style.height = 0;
        mobileNav[i].style.visibility = 'hidden';
        mobileNav[i].style.padding = 0;
        mobileNav[i].style.margin = 0;
      };
    }

    let footer = document.getElementsByClassName('Footer')
    if (footer && footer.length > 0) {
      for (i = 0; i < footer.length; i++) {
        footer[i].style.height = 0;
        footer[i].style.visibility = 'hidden';
        footer[i].style.padding = 0;
        footer[i].style.margin = 0;
      }
    }

    let cookieConsent = document.getElementsByClassName('cc-window')
    if (cookieConsent && cookieConsent.length > 0) {
      for (i = 0; i < cookieConsent.length; i++) {
        cookieConsent[i].style.height = 0;
        cookieConsent[i].style.visibility = 'hidden';
        cookieConsent[i].style.padding = 0;
        cookieConsent[i].style.margin = 0;
      }
    }
  }

  renderTableHeader(name) {
    if (document.getElementById(name)) {
      this.runningPageHeight += document.getElementById(name).clientHeight;
    }

    return (
      <Col xs="12" style={{paddingRight: '4px', paddingTop: '8px' }} id={name}>
        <Row noGutters style={{ border: '1px solid white', borderBottom: 'none' }} className="align-items-end">
          <Col xs="1" className="head">
            Lab Score
          </Col>
          <Col xs="4" className="head" style={{ paddingBottom: '3px', paddingTop: '4px', marginLeft: '-1px', fontSize: '16px' }}>
            {name}
          </Col>
          <Col xs="1" className="head" style={{ marginLeft: '-1px' }}>
            Admit Rate
          </Col>
          <Col xs="1" className="head" style={{ marginLeft: '-1px' }}>
            GPA
          </Col>
          <Col xs="1" className="head" style={{ marginLeft: '-1px' }}>
            SAT
          </Col>
          <Col xs="1" className="head" style={{ marginLeft: '-1px' }}>
            ACT
          </Col>
          <Col xs="1" className="head" style={{ marginLeft: '-1px' }}>
            AP Tests
          </Col>
          <Col className="head" style={{ marginLeft: '-1px', flex: '0 0 9%', maxWidth: '9%' }}>
            Avg. AP Score
          </Col>
          <Col className="head" style={{ marginLeft: '-1px', flex: '0 0 8.2%', maxWidth: '8.2%' }}>
            Subj. Tests
          </Col>
        </Row>
      </Col>
    );
  }

  renderSchools(type) {
    const schoolType = this.state[type];
    return schoolType.sort((a, b) => (b.chanceOfAdmission - a.chanceOfAdmission)).map((school, index) => {
      var currentSchool = document.getElementById(`school-${type}-${index}`);
      if (currentSchool) {
        this.runningPageHeight += currentSchool.clientHeight;
      }

      const heightCopy = this.runningPageHeight;
      let insertPageBreak = this.insertPageBreak(school.displayName);
      // if (insertPageBreak) {
      //   return (
      //     <div key={type + index}>
      //       <div className="page-break-clear"></div>
      //       <div className="page-break">{`PAGE BREAK AT ${heightCopy}`}</div>
      //     </div>
      //   )
      // }

      return (
        <div className={insertPageBreak ? 'page-break' : ''} style={{ paddingRight: '4px' }} key={type + index}>
          <Col xs="12" id={`school-${type}-${index}`} style={{ padding: '0px' }}>
            <Row noGutters className="school-row">
              <Col xs="1" className="cell">
                <p>{`${school.chanceOfAdmission}%`}</p>
              </Col>
              <Col xs="4" className="cell" style={{ marginLeft: '-1px' }}>
                {`${school.displayName} (${school.location[school.location.length - 2]}${school.location[school.location.length - 1]})`}
              </Col>
              <Col xs="1" className="cell" style={{ marginLeft: '-1px' }}>
                <p>{school.admissionRate}</p>
              </Col>
              <Col xs="1" className="cell" style={{ marginLeft: '-1px' }}>
                <p>{school.gpa}</p>
              </Col>
              <Col xs="1" className="cell" style={{ marginLeft: '-1px' }}>
                <p>{school.satTotal}</p>
              </Col>
              <Col xs="1" className="cell" style={{ marginLeft: '-1px' }}>
                <p>{isNaN(school.act) ? this.convertSATtoACT(school.satTotal) : school.act}</p>
              </Col>
              <Col xs="1" className="cell" style={{ marginLeft: '-1px' }}>
                <p>{school.apTestsTaken}</p>
              </Col>
              <Col className="cell" style={{ marginLeft: '-1px', flex: '0 0 9%', maxWidth: '9%' }}>
                <p>{school.ap}</p>
              </Col>
              <Col className="cell" style={{ marginLeft: '-1px', flex: '0 0 8.2%', maxWidth: '8.2%' }}>
                <p>{school.satSubjectTestsTaken}</p>
              </Col>
            </Row>
            {/* { insertPageBreak && <div className='page-break'></div>} */}
          </Col>
        </div>
        )
      }
    );
  }

  convertSATtoACT(totalSat) {
    return (totalSat < 590) ? 11
    : (totalSat < 680) ? 12
    : (totalSat < 740) ? 13
    : (totalSat < 780) ? 14
    : (totalSat < 830) ? 15
    : (totalSat < 870) ? 16
    : (totalSat < 910) ? 17
    : (totalSat < 950) ? 18
    : (totalSat < 990) ? 19
    : (totalSat < 1030) ? 20
    : (totalSat < 1070) ? 21
    : (totalSat < 1110) ? 22
    : (totalSat < 1140) ? 23
    : (totalSat < 1180) ? 24
    : (totalSat < 1220) ? 25
    : (totalSat < 1260) ? 26
    : (totalSat < 1290) ? 27
    : (totalSat < 1320) ? 28
    : (totalSat < 1360) ? 29
    : (totalSat < 1400) ? 30
    : (totalSat < 1430) ? 31
    : (totalSat < 1470) ? 32
    : (totalSat < 1500) ? 33
    : (totalSat < 1540) ? 34
    : (totalSat < 1570) ? 35
    : (totalSat <= 1600) ? 36 : 0;
  }

  insertPageBreak = (name) => {
    const buffer = 50;
    const pageSize = 1000;

    if ((pageSize - this.runningPageHeight) < buffer) {
      this.runningPageHeight = 0;
      return true;
    }

    return false;
  }

  render() {
    if (!this.state.params) return <div />
    this.page = 1;
    this.runningPageHeight = 0;

    if (document.getElementById('profile-div')) {
      this.runningPageHeight = document.getElementById('profile-div').clientHeight;
    }

    const { studentInfo } = this.state.params;
    const { safety, probable, reach } = this.state;

    if (!studentInfo || !safety || !probable || !reach) return <div />

    return (
      <div className='Report' ref={this.reportRef}>
        <Container fluid>
          <div id='profile-div'>
            <Row className="header" noGutters>
              <Col xs="6" style={{ paddingLeft: '4px' }}>
                <div style={{ color: '#0f629b', paddingBottom: '4px' }}><strong>MyLab Report</strong></div>
                <div className="small">{studentInfo.studentName ? studentInfo.studentName : ''}</div>
                <div className="small" style={{ paddingBottom: '2px'}}>{studentInfo.descriptor ? studentInfo.descriptor : ''}</div>
                <div className="small">{moment().format('M/D/YYYY')}</div>
              </Col>
              <Col xs="6" style={{ textAlign: 'right', paddingRight: '4px' }}>
                <img src="/images/logotype-negative.svg" height="45px" style={{ marginBottom: '4px' }} />
                <div className="small" style={{ paddingTop: '2px', textAlign: 'right' }}><strong>www.collegelab.org</strong></div>
              </Col>
            </Row>
            <Row noGutters className="profile">
              <Col xs="12">
                <strong>Profile</strong>
              </Col>
              <Col xs="2">
                <div className="profile-cell">
                  <div className="cell">
                    <div className="title" style={{ lineHeight: '32px' }}>GPA</div>
                    <div className='profile-value'>{studentInfo && studentInfo.GPA}</div>
                  </div>
                </div>
              </Col>
              <Col xs="2">
                <div className="profile-cell">
                  <div className="cell">
                    <div className="title" style={{ lineHeight: '32px' }}>SAT</div>
                    <div className='profile-value'>{studentInfo && studentInfo.SAT}</div>
                  </div>
                </div>
              </Col>
              <Col xs="2">
                <div className="profile-cell">
                  <div className="cell">
                    <div className="title" style={{ lineHeight: '32px' }}>ACT</div>
                    <div className='profile-value'>{studentInfo.ACT == 0 || !studentInfo.ACT ? '-' : studentInfo.ACT}</div>
                  </div>
                </div>
              </Col>
              <Col xs="2">
                <div className="profile-cell">
                  <div className="cell">
                    <div className="title" style={{ lineHeight: '32px' }}># of AP Tests</div>
                    <div className='profile-value'>{studentInfo && studentInfo.numAP}</div>
                  </div>
                </div>
              </Col>
              <Col xs="2">
                <div className="profile-cell">
                  <div className="cell">
                    <div className="title">Avgerage AP Test Scores</div>
                    <div className='profile-value'>{studentInfo && studentInfo.numAP == 0 ? '-' : studentInfo && studentInfo.avgAP}</div>
                  </div>
                </div>
              </Col>
              {/* <Col xs="2">
                <div className="profile-cell">
                  <div className="cell">
                    <div className="title">Number of SAT Subject Tests</div>
                    <div className='profile-value'>{studentInfo && studentInfo.numSubjects}</div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
          <Row noGutters className="my-list">
            <Col xs="12">
              <strong>MyList</strong>
            </Col>
            {safety.length > 0 ? this.renderTableHeader('Likely') : ''}
            {this.renderSchools('safety')}
            <div style={{ height: '4px', width: '100%' }} />
            {probable.length > 0 ? this.renderTableHeader('Target') : ''}
            {this.renderSchools('probable')}
            <div style={{ height: '4px', width: '100%' }} />
            {reach.length > 0 ? this.renderTableHeader('Reach') : ''}
            {this.renderSchools('reach')}
          </Row>
        </Container>
      </div>
    )
  }
}

export default GenerateReport;