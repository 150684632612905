
import { toast } from 'react-toastify';

const createToast  = (type, message) => {
  if (!message && !type) {
    return;
  }

  if (!toast[type]) {
    type = 'default'
  }

  if (!message && type) {
    toast['success'](type, {
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  } else {
    if (type == 'danger') {
      type = 'error';
    }

    toast[type](message, {
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }
}

export default createToast;