import React from 'react';

import { Row, Col, Container, FormGroup, Label, Input, Button } from 'reactstrap';
import salert from '../../modules/salert';

import EndBlockCTA from '../../components/EndBlockCTA/EndBlockCTA';
import MetaUpdater from '../../components/MetaUpdater/MetaUpdater';

import './Contact.scss';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      message: '',
      emailAddress: '',
      isEmailValid: false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { emailAddress, name, message } = this.state;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", emailAddress, name, message })
    }).then(() => {
      this.setState({
        name: '',
        message: '',
        emailAddress: '',
      });

      salert('info', 'Thank you for your request, we will be in touch');
    }).catch(error => alert(error));

    e.preventDefault();
  }

  render() {
    return (
      <div>
        <MetaUpdater
          title='College Admissions | College Counseling | CollegeLab'
          description='CollegeLab Contact Information, Email, Address, Phone Numbers. Contact Us to Find What it Takes for Ivy League Admissions. Get Beyond Rankings.' />
        <Container className='Contact'>
          <form onSubmit={this.handleSubmit} id='contact'>
            <Row>
              <Col xs='12'>
                <h1 className='text-center banner'>Get in touch!</h1>
                <h6 className='text-center' style={{ fontSize: '20px' , paddingBottom: '42px' }}>Share your questions, feedback, or anything else.</h6>
              </Col>
              <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
                <Row>
                  <Col xs='12' md='6'>
                    <FormGroup>
                      <Label>Name</Label>
                      <Input type="text" name="name" placeholder="Jane Doe" required='true' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </FormGroup>
                  </Col>
                  <Col xs='12' md='6'>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input type="email" name="_replyto" placeholder="jane@doe.com" required='true' value={this.state.emailAddress}
                        onChange={(e) => {
                          var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                          let valid = re.test(String(e.target.value).toLowerCase());
                          this.setState({
                            emailAddress: e.target.value,
                            isEmailValid: valid
                          })
                        }}
                        invalid={this.state.emailAddress.length > 0 && !this.state.isEmailValid} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup xs='12'>
                      <Label>Message</Label>
                      <Input type='textarea' name='message' placeholder='Type your message here...' required='true' value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} />
                    </FormGroup>
                  </Col>
                  <Col xs="12" style={{ textAlign: 'center' }}>
                    <Button className='round-borders' disabled={!this.state.name || !this.state.emailAddress || !this.state.message || !this.state.isEmailValid}type='submit'>Send</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Container>
        <EndBlockCTA />
      </div>
    );
  }
}

export default Contact;
