import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import FreeTrialForm from '../FreeTrialForm/FreeTrialForm';

import './EndBlockCTA.scss';

class EndBlockCTA extends React.Component {
  render() {
    return (
      <div className='EndBlockCTA'>
        <Container>
          <Row>
            <Col xs='12' sm='12' md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
              <div style={{ padding: '15px' }}>
                <FreeTrialForm buttonColor='primary' _id='free-trial-cta' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default EndBlockCTA;