import React from 'react';

import centsToDollars from '../../modules/centsToDollars';
import { FormGroup, Label, Input } from 'reactstrap';

import './Plans.scss';

class Plans extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      plan: props.currentPlan,
      plans: []
    };
  }

  componentDidMount() {
    runGetPlans().then(res => {
      this.setState({ plans: res.plans });
    })
  }

  render() {
    const { currentPlan } = this.props;
    const { plans } = this.state;

    return (
      <div className="Plans">
        {plans.sort((a,b) => { return a.price - b.price }).map(({ planId, label, price, isFreeTrial }) => {
          const isCurrentPlan = currentPlan === planId;
          return (
            <FormGroup check inline key={planId + "radio"} className="Plan"
            onClick={() => { 
              this.setState({ plan: planId });
              if (this.props.planChange) {
                this.props.planChange(price/100, isFreeTrial);
              }
            }}>
              <Label check key={planId} for={planId} className="custom-radio">
                <Input
                  id={planId}
                  readOnly
                  className="custom-control-input"
                  type="radio"
                  name="plan"
                  value={planId}
                  checked={this.state.plan === planId}
                  disabled={isCurrentPlan}
                />{' '}
                <span className="custom-control-indicator"></span>
                <span className="custom-control-description">
                  {!isFreeTrial && centsToDollars(price) + ' - ' + label}
                  {isFreeTrial && label}
                </span>
              </Label>
            </FormGroup>
          );
        })}
      </div>
    );
  }
}

export default Plans;

const runGetPlans = async () => {
  const response = await fetch('/api/v1/plans', {
    method: "GET"
  }).then(response => {
    return response
  }, function(error) {
    console.log(error);
  });

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);

  return body;
}