import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import settings from '../../settings';

import './Footer.scss';

const copyrightYear = () => {
  const currentYear = '2018'
  return currentYear === '2017' ? '2017' : `2017-2021`;
};

const Footer = () => (
  <Container className="Footer">
    <Row className='top-footer align-items-center'>
      <Col xs='12' sm='12' md='12' lg='auto' className='logo'>
        <div><img height='42px' src='https://www.collegelab.org/images/logo-flat.svg' alt='logotype-negative' /></div>
        <div style={{ marginTop: '12px' }}><small>Allura AI LLC © {copyrightYear()}</small></div>
      </Col>
      <Col xs='12' sm='12' md='12' lg='auto' className='ml-auto footer-link'>
        <Link to='/about'>About</Link>
      </Col>
      {/* <Col xs='12' sm='12' md='12' lg='auto' className='footer-link'>
        <Link to='/faqs'>FAQs</Link>
      </Col> */}
      {/*<Col xs='12' sm='12' md='12' lg='auto' className='footer-link'>
        <Link to='/pricing'>Pricing</Link>
    </Col>*/}
      <Col xs='12' sm='12' md='12' lg='auto' className='footer-link'>
        <Link to='/contact'>Contact</Link>
      </Col>
      <Col xs='12' sm='12' md='12' lg='auto' className='footer-link'>
        <a href={settings.APP_URL + '/terms'}>Terms of Service</a>
      </Col>
      <Col xs='12' sm='12' md='12' lg='auto' className='footer-link'>
        <a href={settings.APP_URL + '/privacy'}>Privacy Policy</a>
      </Col>
    </Row>
    <Row>
      <Col className='disclaimer' xs='12'>
        SAT®  is a registered trademark of the College Board, which is not affiliated with, and does not endorse, this site.
      </Col>
      <Col className='disclaimer' xs='12'>
        ACT®  is a registered trademark of the ACT, which is not affiliated with, and does not endorse, this site.
      </Col>
      <Col className='disclaimer' xs='12'>
        This service has been developed independently from and is not endorsed by the International Baccalaureate Organization. International Baccalaureate, Baccalauréat International, Bachillerato Internacional and IB are registered trademarks owned by the International Baccalaureate Organization.
      </Col>
    </Row>
  </Container>
);

export default Footer;
