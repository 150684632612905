import lunr from 'lunr';

const createLunrDocument = (documents, fields, sessionName) => {
  let idx = lunr(function () {
    this.ref('_id');
    fields.forEach((field) => this.field(field.name, field.attributes));
    documents.forEach((document) => this.add(document));
  });

  if (!window.idx) {
    window.idx = {}
  }

  window.idx[sessionName] = idx;
}

const lunrSearch = async (idx, string, values, findOne) => {
  if (!string || string.length < 3) {
    return {
      result: values,
      total: values.total
    }
  }

  if (!window.idx || !window.idx[idx]) {
    return {
      result: [],
      total: 0
    };
  }

  if (findOne) {
    let res = window.idx[idx].search(string);
    if (res && res[0]) {
      let result = [];
      result.push(values.find(value => value._id == res[0].ref));
      return {
        result,
        total: 1
      }
    }
  }

  if (string.length >= 4) {
    if (!string.includes(' ')) {
      string = string.substring(0, string.length - 2) + '*'
    } else {
      string = string.replace(' ', '~1 ');
    }
  }

  const res = window.idx[idx].search(string);
  if (res.length == 0) {
    return {
      result: [],
      total: 0
    };
  }

  let stanDev = res[0].score * 0.5;
  if (res.length < 10) {
    stanDev = res[0].score * 0.5;
  }
  
  let result = values.filter(value => {
      return res.findIndex(item => item.ref == value._id && item.score > stanDev) != -1
    })
    .sort((a, b) => {
      if (string.length < 5) return 0;

      if (!a.forbesRank) return 1;
      if (!b.forbesRank) return -1;
      if(a.forbesRank > b.forbesRank) return -1;
      if(a.forbesRank < b.forbesRank) return 1;
      return 0;
    })
    .slice(0, 25);

  return ({
    result,
    total: result.length
  });
}

export { lunrSearch, createLunrDocument };