import React from 'react';

import { Row, Col, Container, Button, Input, Label, FormGroup, FormFeedback, Form, Modal, ModalHeader, ModalBody, Carousel, CarouselIndicators, CarouselItem, CarouselControl } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import Lightbox from 'react-image-lightbox';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect } from 'react-router-dom';

import EndBlockCTA from '../../components/EndBlockCTA/EndBlockCTA';
import SignupModal from '../../components/SignupModal/SignupModal';
import MetaUpdater from '../../components/MetaUpdater/MetaUpdater';
import FreeTrialForm from '../../components/FreeTrialForm/FreeTrialForm';

import './Index.scss';

import centsToDollars from '../../modules/centsToDollars';
import schoolToURI from '../../modules/schoolToURI';
import RequestDemoModal from '../../components/RequestDemoModal/RequestDemoModal';
import formatNumber from '../../modules/format-number';
import CollegeSearchDropdown from './CollegeSearchDropdown';
import settings from '../../settings';
import MarketingModal from '../Modals/MarketingModal';

const items = [
  {
    src: '/images/sc1.jpg',
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: '/images/sc2.jpg',
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: '/images/sc3.jpg',
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: '/images/sc4.jpg',
    altText: 'Slide 1',
    caption: 'Slide 1'
  }
];

const lightboxImages = [
  '/images/predictions.png',
  '/images/my-list.png',
  '/images/lab.png',
  '/images/school-page.jpg'
]

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.WAIT_INTERVAL = 250;

    this.state = {
      numberOfRuns: 0,
      GPA: 0,
      SAT: 0,
      earlyApp: false,
      private: false,
      isLoading: false,
      schools: [],
      interval: '',
      signupModal: false,
      modal: false,
      isOpen: false,
      carouselIndex: 0,
      isLightboxOpen: false,
      photoIndex: 0,
      localSchools: JSON.parse(localStorage.getItem('localSchools')),
      isDemoModalOpen: false,
      redirect: null,
      isMarketingModalOpen: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleSignupModal = this.toggleSignupModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.carouselIndex === items.length - 1 ? 0 : this.state.carouselIndex + 1;
    this.setState({ carouselIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.carouselIndex === 0 ? items.length - 1 : this.state.carouselIndex - 1;
    this.setState({ carouselIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ carouselIndex: newIndex });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleRedirect = (e) => {
    let slug = schoolToURI(e.currentTarget.getAttribute('data-college-name'));
    window.location.href = `${settings.APP_URL}/colleges/${slug}`;
    // this.setState({ redirect: `/colleges/${slug}` })
  }

  toggleDemoModal = () => this.setState({ isDemoModalOpen: !this.state.isDemoModalOpen });

  toggleMarketingModal = () => this.setState({ isMarketingModalOpen: !this.state.isMarketingModalOpen });

  componentDidMount() {
    const { localSchools } = this.state;

    let marketingCookie = this.getCookie('marketing_cookie');
    if (!marketingCookie) {
      setTimeout(() => {
        this.toggleMarketingModal();
      }, 15000)

      const d = new Date();
      d.setTime(d.getTime() + (5 * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = `marketing_cookie=true;${expires};path=/`;
    }

    // if we don't have localschools saves in storage
    fetchLocalSchools()
      .then(res => {
        if (res && res.colleges) {
          this.setState({ localSchools: res.colleges });
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  componentWillMount() {
    this.timer = null;
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  toggleSignupModal() {
    this.setState({
      signupModal: !this.state.signupModal
    });
  }

  handleSubmit() {
    if (this.state.GPA >= 0 && this.state.GPA <= 4 && this.state.SAT >= 0 && this.state.SAT <= 1600 && !this.state.isLoading) {
      this.setState({ GPA: this.state.GPA ? this.state.GPA : 0, SAT: this.state.SAT ? this.state.SAT : 0, isLoading: true }, () => {

        this.timer = setTimeout(() => {
          callApi(this.state.GPA, this.state.SAT, this.state.earlyApp, this.state.private)
            .then(res => {
              var numberOfRuns = this.state.numberOfRuns + 1;
              var signupModal = false;

              if (numberOfRuns === 3 || numberOfRuns === 7 || (numberOfRuns > 10 && numberOfRuns % 10 === 0)) {
                signupModal = true;
              }

              this.setState({
                schools: res.data,
                isLoading: false,
                numberOfRuns,
                signupModal
              });
            })
            .catch(err => {
              console.log(err);
              this.setState({
                isLoading: false,
                schools: []
              });
            });
        }, this.WAIT_INTERVAL);
      })
    }
  }

  renderFirstBlock() {
    return (
      <div className='first-block'>
        <Container style={{ color: 'white' }}>
          <Row>
            <Col xs='12' sm='12' md='12' lg='6'>
              <Row>
                <Col xs='12'></Col>
                <Col xs='12' className='subtitle'>
                  We use artificial intelligence and expert admissions insights to help you manage the college process and improve your chance of acceptance.
                </Col>
                <Col xs='12' sm='12' md='12' lg='10' style={{ fontSize: '20px' }}>
                  <LinkContainer to='/signup' style={{ padding: '15px 46px' }}>
                    <Button block color='primary'>Get Started</Button>
                  </LinkContainer>
                  {/* <Button block color='secondary' outline href='/guided-path-transition-offer' style={{ padding: '15px 46px', marginTop: '12px' }}>GuidedPath Special Offer!</Button> */}
                </Col>
                <Col className='subtitle' xs='12' sm='12' md='12' lg='10' style={{ padding: '25px 15px 0px 15px' }}>
                  New on CollegeLab: learn about student loan opportunities!  Click the link below to learn more and start your application today.
                </Col>
                <Col xs='12' sm='12' md='12' lg='10' style={{ fontSize: '20px' }}>
                  <Button block color='primary' style={{ padding: '15px 46px' }} href='https://collegelab.sparrowfi.com' target='_blank' rel="noopener noreferrer">
                    Learn More!
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <SignupModal isOpen={this.state.modal} toggle={this.toggle} />
          <MarketingModal isOpen={this.state.isMarketingModalOpen} toggle={this.toggleMarketingModal} />
        </Container >
      </div >
    );
  }

  setScoreColor(chanceOfAdmission) {
    var backgroundColor;
    var color = '#fff';

    if (chanceOfAdmission < 12) { backgroundColor = '#ff210a' } else                // red
      if (chanceOfAdmission < 24) { backgroundColor = '#fe8d1c' } else
        if (chanceOfAdmission < 37) {
          backgroundColor = '#fed903';
          color = '#949494';
        } else if (chanceOfAdmission < 49) { backgroundColor = '#ced42c' } else
          if (chanceOfAdmission < 62) { backgroundColor = '#b1d42c' } else
            if (chanceOfAdmission < 74) { backgroundColor = '#47dc66' } else
            //if (chanceOfAdmission < 87) { backgroundColor = '#6BBD7B' } else
            { backgroundColor = '#00cf81' }                                   // green

    return ({
      background: backgroundColor,
      color: color
    });
  }

  setBorderColor(chanceOfAdmission) {
    var borderColor;

    if (chanceOfAdmission < 12) { borderColor = '#ff210a' } else                // red
      if (chanceOfAdmission < 24) { borderColor = '#fe8d1c' } else
        if (chanceOfAdmission < 37) {
          borderColor = '#fed903';
        } else if (chanceOfAdmission < 49) { borderColor = '#ced42c' } else
          if (chanceOfAdmission < 62) { borderColor = '#b1d42c' } else
            if (chanceOfAdmission < 74) { borderColor = '#47dc66' } else
            //if (chanceOfAdmission < 87) { borderColor = '#6BBD7B' } else
            { borderColor = '#00cf81' }                                   // green

    return ({
      borderColor: borderColor
    });
  }

  renderSchools() {
    return this.state.schools.map((school, index) => {
      return (
        <Col xs='12' sm='12' md='3' key={'school' + index} className='college-cell'>
          <Row className="align-items-center" style={{ height: '100%' }}>
            <Col xs='9' sm='9' className="school-name">
              {school.name}
            </Col>
            <Col xs="3">
              <div className="score-box" style={this.setScoreColor(school.chanceOfAdmission)}>
                <div style={{ zIndex: '10', position: 'relative', marginLeft: '-12px' }}>{school.chanceOfAdmission + "%"}</div>
                <div className='score-color-background' style={this.setScoreColor(school.chanceOfAdmission)} />
              </div>
            </Col>
          </Row>
        </Col>
      )
    })
  }

  renderSchoolsLoading() {
    var loading = [];
    for (var i = 0; i < 4; i++) {
      loading.push(
        <Col xs='12' sm='12' md='3' key={'school' + i} className='college-cell'>
          <Row className="align-items-center">
            <Col xs='9' sm='9' className="school-name">
            </Col>
            <Col xs="3">
              <div className="score-box">
                <div style={{ zIndex: '10', position: 'relative', color: '#0f629b', marginLeft: '-12px' }}><FontAwesome name='spinner' spin /></div>
                <div className='score-color-background' />
              </div>
            </Col>
          </Row>
        </Col>
      )
    }

    return loading;
  }

  renderTestWidget() {
    return (
      <div className='test-widget'>
        <Container style={{ color: 'black' }}>
          <Row>
            <Form onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}>
              <Col xs='12' className='widget'>
                <Row className='align-items-center'>
                  <Col xs='12' style={{ fontWeight: 'bold', fontSize: '24px', padding: '24px 24px 1rem 24px', borderBottom: '1px solid #ddd', background: '#f6f6f6', borderRadius: '4px 4px 0 0' }}>
                    <span>College Acceptance Calculator</span>
                    {this.state.numberOfRuns > 0
                      ? <span className='desktop-run-btn'>
                        <Button size='lg' color='primary' style={{ float: 'right', marginTop: '2px' }} type='submit'>
                          Show me More Schools<FontAwesome name='flask' style={{ marginLeft: '8px', height: '21px' }} />
                        </Button>
                      </span>
                      : ''}
                  </Col>
                </Row>
                <Row style={{ padding: '24px 15px' }}>
                  <Col xs='12' sm='12' md='3' className='widget-input'>
                    <Label>Unweighted GPA</Label>
                    <Input type='number' step="0.01" onChange={(e) => { this.setState({ GPA: e.target.value }) }} value={this.state.GPA} invalid={this.state.GPA > 4 || this.state.GPA < 0} />
                    <FormFeedback>Must be less than 4.0</FormFeedback>
                  </Col>
                  <Col xs='12' sm='12' md='3' className='widget-input'>
                    <Label>SAT or ACT Score</Label>
                    <Input type='number' onChange={(e) => { this.setState({ SAT: e.target.value }) }} value={this.state.SAT} invalid={this.state.SAT > 1600 || this.state.SAT < 0} />
                    <FormFeedback>Must be less than 1600</FormFeedback>
                  </Col>
                  <Col xs='12' sm='12' md='3' className='widget-radio'>
                    <FormGroup tag="fieldset">
                      <legend>I am planning to apply early</legend>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ earlyApp: false })}>
                          <Input
                            checked={!this.state.earlyApp}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">No</span>
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ earlyApp: true })}>
                          <Input
                            checked={this.state.earlyApp}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">Yes</span>
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col xs='12' sm='12' md='3' className='widget-radio'>
                    <FormGroup tag="fieldset">
                      <legend>My high school is:</legend>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ private: false })}>
                          <Input
                            checked={!this.state.private}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">Public</span>
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check className="custom-radio" onClick={() => this.setState({ private: true })}>
                          <Input
                            checked={this.state.private}
                            readOnly
                            type="radio"
                            className="custom-control-input"
                          />{' '}
                          <span className="custom-control-indicator"></span>
                          <span className="custom-control-description">Private</span>
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  {this.state.isLoading
                    ? this.renderSchoolsLoading()
                    : this.state.numberOfRuns > 0
                      ? this.renderSchools()
                      : <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} className='initial-run-btn'>
                        <div style={{ paddingBottom: '1rem' }}>Try a limited sample version of our Predictor using 4 of our 12 data points. Our algorithm is one of the many tools we have to help you navigate the college admissions process.</div>
                        <Button color='info' size='lg' type='submit'>Tell me my Chances<FontAwesome name='flask' style={{ marginLeft: '8px', height: '21px' }} /></Button>
                      </Col>}
                  {this.state.numberOfRuns > 0
                    ? <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} className='mobile-run-btn' style={{ textAlign: 'center' }}>
                      <div style={{ paddingBottom: '1rem' }}>Try a limited sample version of our predictor using 4 of our 12 data points, one of our many tools we have to help you navigate the college admissions process.</div>
                      <Button color='info' size='lg' type='submit'>Tell me my Chances<FontAwesome name='flask' style={{ marginLeft: '8px', height: '21px' }} /></Button>
                    </Col>
                    : ''}
                </Row>
              </Col>
            </Form>
          </Row>
        </Container>
      </div>
    )
  }

  renderSecondBlock() {
    return (
      <div className='second-block'>
        <Container style={{ color: 'black' }}>
          <Row>
            <Col xs='12' className='title'>
              Empowering Students and Parents
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <Row className='align-items-center'>
                <Col xs='12' sm='12' md='6'>
                  <div style={{ paddingBottom: '30px' }}><img className='lozad' alt='stat-icon' height='64px' data-src='/images/stats-icon.svg' /></div>
                  <div className='subtitle'>Evaluate Your Chance</div>
                  <div className='copy'><span className='block'>Compare your profile to millions of students</span><span className='block'> accepted at hundreds of colleges and universities.</span></div>
                </Col>
                <Col xs='12' sm='12' md='6'>
                  <img alt='college-list' className='content-image lozad' style={{ borderRadius: '14px', boxShadow: '0 16px 48px 0 #2f2b581c', marginTop: '36px', marginBottom: '36px' }} data-src='/images/college-list.svg' />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderThirdBlock() {
    return (
      <div className='third-block'>
        <Container style={{ color: 'black' }}>
          <Row>
            <Col xs='12'>
              <Row className='align-items-center'>
                <Col xs={{ size: 12, order: 2 }} sm={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                  <img alt='my-stats' className='img-fluid content-image lozad' data-src='/images/my-stats.jpg' style={{ borderRadius: '14px', boxShadow: '0 16px 48px 0 #2f2b581c', marginTop: '80px', marginBottom: '80px' }} />
                </Col>
                <Col xs={{ size: 12, order: 1 }} sm={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                  <div style={{ paddingBottom: '30px' }}><img className='lozad' alt='edit-icon' height='64px' data-src='/images/edit-icon.svg' /></div>
                  <div className='subtitle'>Aim High and Achieve</div>
                  <div className='copy'><span className='block'>Create and edit multiple profiles to see how</span><span className='block'> adjustments impact your chances of</span><span className='block'> acceptance.</span></div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderFourthBlock() {
    return (
      <div className='fourth-block'>
        <Container style={{ color: 'black' }}>
          <Row>
            <Col xs='12'>
              <Row className='align-items-center'>
                <Col xs='12' sm='12' md='5'>
                  <div style={{ paddingBottom: '30px' }}><img alt='list-icon' height='64px' className='lozad' data-src='/images/school-icon.svg' /></div>
                  <div className='subtitle'>Create a Balanced List</div>
                  <div className='copy'><span className='block'>Curate your list of schools to make sure you</span><span className='block'> have the right blend of reach, target, and safety</span><span className='block'> schools.</span></div>
                </Col>
                <Col xs='12' sm='12' md='7'>
                  <img className='content-image lozad' alt='my-list' data-src='/images/my-list-stacked.jpg' />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderFifthBlock() {
    const { carouselIndex } = this.state;
    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText} className='img-fluid lozad' />
        </CarouselItem>
      );
    });

    return (
      <div className='fifth-block'>
        <Container style={{ color: 'black', overflow: 'hidden' }}>
          <Row>
            <Col xs='12' className='copy'>
              <span className='block'><strong>Identify</strong> your strengths and weaknesses to <strong>improve</strong></span><span className='block'> your chances of admission, and <strong>compare</strong> your profile</span><span className='block'> to millions of students around the world.</span>
            </Col>
            <Col xs='12' className='content'>
              <div style={{ marginTop: '36px', marginBottom: '-16px' }}>
                <Carousel
                  activeIndex={carouselIndex}
                  next={this.next}
                  previous={this.previous}
                >
                  <CarouselIndicators items={items} activeIndex={carouselIndex} onClickHandler={this.goToIndex} />
                  {slides}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderSixthBlock() {
    return (
      <div className='sixth-block'>
        <Container style={{ color: 'black' }} fluid>
          <Row>
            {/* <Col xs='12' style={{ color: '#3b4145', fontSize: '36px', paddingBottom: '80px' }}>
              CollegeLab was developed by top data scientists with<br />the help of a team of senior admissions officers led by<br />a former Ivy League dean of admissions.
            </Col> */}
            <Col xs='12' sm='12' md={{ size: 4, offset: 7 }}>
              <FreeTrialForm _id='free-trial-index-after-widget' />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderSeventhBlock() {
    return (
      <div className='seventh-block'>
        <Container style={{ color: 'black' }}>
          <Row>
            <Col xs='12' style={{ color: '#0f629b', fontSize: '36px', fontWeight: 'bold', paddingBottom: '64px' }}>
              Find Your Best Fit College or University
            </Col>
            <Col xs='12' sm='12' md='6'>
              <img data-src='/images/predictions.png' alt='book-icon' className='lozad img-fluid' style={{ marginBottom: '12px', border: '1px solid #d6d6d6', borderRadius: '4px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12)', cursor: 'pointer' }} onClick={() => this.setState({ isLightboxOpen: true, photoIndex: 0 })} />
              <div className='copy'><span className='block'>Utilizes knowledge from top admissions experts</span><span className='block'> and insiders to give you an advantage.</span></div>
            </Col>
            <Col xs='12' sm='12' md='6'>
              <img data-src='/images/my-list.png' alt='book-icon' className='lozad img-fluid' style={{ marginBottom: '12px', border: '1px solid #d6d6d6', borderRadius: '4px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12)', cursor: 'pointer' }} onClick={() => this.setState({ isLightboxOpen: true, photoIndex: 1 })} />
              <div className='copy'><span className='block'>Provides greater insights and analytics to</span><span className='block'> students, their families, and counselors</span><span className='block'> at a fraction of the cost.</span></div>
            </Col>
            <Col xs='12' sm='12' md='6'>
              <img data-src='/images/lab.png' alt='book-icon' className='lozad img-fluid' style={{ marginBottom: '12px', border: '1px solid #d6d6d6', borderRadius: '4px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12)', cursor: 'pointer' }} onClick={() => this.setState({ isLightboxOpen: true, photoIndex: 2 })} />
              <div className='copy'><span className='block'>Applies machine learning algorithms and</span><span className='block'> expert insights to millions of student</span><span className='block'> applications.</span></div>
            </Col>
            <Col xs='12' sm='12' md='6'>
              <img data-src='/images/school-page.jpg' alt='book-icon' className='lozad img-fluid' style={{ marginBottom: '12px', border: '1px solid #d6d6d6', borderRadius: '4px', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12)', cursor: 'pointer' }} onClick={() => this.setState({ isLightboxOpen: true, photoIndex: 3 })} />
              <div className='copy'><span className='block'>Find all of the information you need about</span><span className='block'>  a school quickly and easily to make</span><span className='block'> sure it's the right fit for you.</span></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderCustomersBlock() {
    return (
      <div className='customers-block' style={{ borderTop: "1px solid #eee" }}>
        <Container fluid>
          <Row>
            <Col className='text-center text-muted' xs='12' style={{ fontWeight: 'bold', fontSize: '36px', paddingBottom: '10px' }}>
              <span>Proudly Serving</span>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/joyce-ivy.png" alt="Joyce Ivy" />
            </Col>
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/lone-pine-foundation-logo.png" alt="Lone Pine" />
            </Col>
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/gsusa.png" alt="GSUSA" />
            </Col>
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/minds-matter.png" alt="Minds Matter" />
            </Col>

          </Row>
        </Container>
      </div>
    )
  }

  renderPartnersBlock() {
    return (
      <div className="partners-block" style={{ borderTop: "1px solid #ddd" }}>
        <Container fluid>
          <Row>
            <Col className='text-center text-muted' xs='12' style={{ fontWeight: 'bold', fontSize: '36px', paddingBottom: '10px' }}>
              <span>Our Partners</span>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/college-confidential-logo2.png" alt="College Confidential" />
            </Col>
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/prompt-logo.png" alt="Prompt" />
            </Col>
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/cg_logo.png" alt="Rate My Teachers" />
            </Col>
            <Col className="py-4 text-center" xs="12" sm="12" md='6' lg='3'>
              <img className='lozad img-fluid' src="/images/lending-tree-logo.png" alt="Lending Tree" />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }


  renderCollegeBlock() {
    const { localSchools } = this.state;

    return (
      <div className='college-block'>
        <Container>
          <Row>
            <Col xs='12' style={{ fontSize: '36px', fontWeight: '500', paddingBottom: '4px', textAlign: 'center' }}>
              One Place for Your College Search
            </Col>
            <Col xs='12' style={{ paddingBottom: '24px', fontWeight: 500 }}>
              CollegeLab provides you all the resources you need to complete your college search.
            </Col>
            <Col xs='12' sm='12' md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
              <div style={{ position: 'relative', paddingBottom: '24px' }}>
                <CollegeSearchDropdown onCollegeClick={this.handleRedirect} onClear={() => null} />
              </div>
            </Col>
            {localSchools && localSchools[0] && this.renderSchoolCard(localSchools[0])}
            {localSchools && localSchools[1] && this.renderSchoolCard(localSchools[1])}
            {localSchools && localSchools[2] && this.renderSchoolCard(localSchools[2])}
          </Row>
        </Container>
      </div>
    )
  }

  renderSchoolCard(school) {
    return (
      <Col xs='12' sm='12' md='4'>
        <div className='college-card'>
          <div style={{ padding: '0px 15px 8px 15px', borderBottom: '1px solid #ddd' }}>
            <div className='title'>{school.name}</div >
            <div><FontAwesome name='map-pin' style={{ marginRight: '8px' }} />{school.location}</div>
          </div>
          <div style={{ borderRadius: '0px 0px 8px 8px', background: '#f6f6f6', padding: '8px 15px 24px 15px' }}>
            <Row>
              <Col xs='6' className='stat-col'>
                <strong>Average Cost</strong>
              </Col>
              <Col xs='6' className='stat-col'>
                {centsToDollars(school.netPrice * 100)}
              </Col>
              <Col xs='6' className='stat-col'>
                <strong>Admit Rate</strong>
              </Col>
              <Col xs='6' className='stat-col'>
                {`${(school.admissionRate * 100).toFixed(1)}%`}
              </Col>
              <Col xs='6' className='stat-col'>
                <strong>Undergrads</strong>
              </Col>
              <Col xs='6' className='stat-col'>
                {formatNumber(school.ugds, 0)}
              </Col>
              <Col xs={{ size: 8, offset: 2 }} style={{ paddingTop: '24px' }}>
                <Button href={`${settings.APP_URL}/colleges/` + schoolToURI(school.name)} size='sm' color='info' block>Learn More</Button>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    )
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to={redirect} />
    }

    return (
      <div className='Index'>
        <MetaUpdater
          title='College Admissions | College Counseling | CollegeLab'
          description='CollegeLab Helps You Identify Best Fit Colleges. Get the Guidance You Need to Succeed in College Admissions and Get Into Top Choice Universities.' />
        {this.renderFirstBlock()}
        {this.renderTestWidget()}
        {this.renderSixthBlock()}
        {this.renderCustomersBlock()}
        {this.renderCollegeBlock()}
        {this.renderSeventhBlock()}
        {this.renderFifthBlock()}
        {this.renderPartnersBlock()}
        {/* {this.renderSecondBlock()} */}
        {/* {this.renderThirdBlock()} */}
        {/* {this.renderFourthBlock()} */}
        <EndBlockCTA />
        <Modal isOpen={this.state.signupModal} toggle={this.toggleSignupModal} className={this.props.className}>
          <ModalHeader toggle={this.toggleSignupModal}><strong>Get Started With CollegeLab</strong></ModalHeader>
          <ModalBody style={{ paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px' }}>
            <FreeTrialForm hideTitle _id='free-trial-widget-modal' />
          </ModalBody>
          {/* <ModalFooter>
            <Button href='https://app.collegelab.org/signup' block color="info" size='lg' onClick={this.toggleSignupModal}>Get Started<span style={{ position: 'absolute', right: '36px', marginTop: '-1px' }}><FontAwesome name='angle-right' size='lg'/> <FontAwesome name='angle-right' size='lg' /> <FontAwesome name='angle-right' size='lg' /></span></Button>{' '}
          </ModalFooter> */}
        </Modal>
        <RequestDemoModal isOpen={this.state.isDemoModalOpen} toggle={this.toggleDemoModal} />
        {this.state.isLightboxOpen && (
          <Lightbox
            mainSrc={lightboxImages[this.state.photoIndex]}
            nextSrc={lightboxImages[(this.state.photoIndex + 1) % lightboxImages.length]}
            prevSrc={lightboxImages[(this.state.photoIndex + lightboxImages.length - 1) % lightboxImages.length]}
            onCloseRequest={() => this.setState({ isLightboxOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + lightboxImages.length - 1) % lightboxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % lightboxImages.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default Index;

const callApi = async (GPA, SAT, earlyApp, privateSchool) => {
  const response = await fetch('/api/v1/random', {
    method: "POST",
    body: JSON.stringify({
      GPA: GPA.toString(),
      SAT: SAT.toString(),
      earlyApp: earlyApp ? '1' : '-1',
      private: privateSchool ? '1' : '-1'
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response
  }, function (error) {
    console.log(error);
  });

  const body = await response.json();

  if (response.status !== 200) throw Error(body.message);

  return body;
};

const fetchLocalSchools = async () => {
  const response = await fetch('/api/v1/college/local', {
    method: "GET",
  }).then(response => {
    return response
  }, function (error) {
    console.log(error);
  });

  const body = await response.json();

  if (response.status !== 200) throw Error(body.message);

  return body;
};