import React from 'react';
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router';

class SignupModal extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname != this.props.location.pathname && this.props.isOpen) {
      this.props.toggle();
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalBody>
          <Row>
            <Col xs='12' style={{ marginBottom: '15px' }}>
              <Button block size='lg' color='primary' href='https://app.collegelab.org/counselor-signup'>Counselor</Button>
            </Col>
            <Col xs='12'>
              <LinkContainer to='/signup'>
                <Button block size='lg' color='info'>Student/Parent</Button>
              </LinkContainer>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default withRouter(SignupModal);