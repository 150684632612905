import React from 'react';
import { Nav, NavItem, NavLink, Container, Navbar, Row, Col, Button } from 'reactstrap';

import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap';

class ScrollNav extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.getElement();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  getElement = () => {
    var element = document.getElementById('scroll-nav');
    if (element) {
      this.element = element;
    } else {
      setTimeout(() => {
        this.getElement();
      }, 500)
    }
  }

  handleScroll = () => {
    requestAnimationFrame(this.checkHeight)
  }

  checkHeight = () => {
    var { heightToScrollAt } = this.props;

    heightToScrollAt = heightToScrollAt ? heightToScrollAt : 164 - 72;

    if (!!this.element) {
      if (window.scrollY >= heightToScrollAt) {
        if (!this.element.classList.contains('scroll-nav')) {
          this.element.classList.remove('hidden-nav');
          this.element.classList.add('scroll-nav');
        }
      } else {
        if (!this.element.classList.contains('hidden-nav')) {
          this.element.classList.remove('scroll-nav');
          this.element.classList.add('hidden-nav');
        }
      }
    }
  }

  render() {
    return (
      <div className='hidden-nav' id='scroll-nav'>
        <Container>
          <Row className='align-items-center'>
            <Col xs='3'>
              <IndexLinkContainer to="/">
                <img src={`/images/logo-flat.svg`} height='36px' />
              </IndexLinkContainer>
            </Col>
            <Col xs='auto' className='ml-auto'>
              <Navbar expand="md" id="navbar-id" className='nav-nav' style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <Nav navbar style={{ alignItems: 'center' }}>
                  <NavItem>
                    <IndexLinkContainer to="/" style={{ marginRight: '15px' }}>
                      <NavLink>Home</NavLink>
                    </IndexLinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer style={{ marginRight: '15px' }} to="/counselors">
                      <NavLink>Counselors</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer style={{ marginRight: '15px' }} to="/about">
                      <NavLink>About</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <NavLink style={{ marginRight: '15px' }} href="https://blog.collegelab.org/">Blog</NavLink>
                  </NavItem>
                  <NavItem>
                    <a href='https://app.collegelab.org'>
                      <NavLink style={{ marginRight: '15px' }}>Sign In</NavLink>
                    </a>
                  </NavItem>
                  <NavItem style={{ marginLeft: '15px' }}>
                    <Button color='primary' onClick={this.props.toggle}>Get Started</Button>
                  </NavItem>
                  {/* <NavItem>
                    <a href='https://app.cyberbuoy.io/signup'>
                      <Button color='accent-down'>Sign Up</Button>
                    </a>
                  </NavItem> */}
                </Nav>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default ScrollNav;