/* eslint-disable max-len */
import React from 'react';
import { Row, Col } from 'reactstrap';

import { Link, Redirect } from 'react-router-dom';

import moment from 'moment';

import './Blog.scss';

class PostList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skip: 0,
      limit: 5,
      posts: []
    }
  }
  
  componentDidMount() {
    var skip = this.props.currentPage * this.props.resultsPerPage - this.props.resultsPerPage;
    var limit = this.props.resultsPerPage;

    runGetPosts(skip, limit).then(res => {
      if (res.posts) {
        this.setState({ posts: res.posts });
      }
    });
  }

  componentDidUpdate() {
    var skip = this.props.currentPage * this.props.resultsPerPage - this.props.resultsPerPage;
    var limit = this.props.resultsPerPage;

    if (skip !== this.state.skip) {
      this.setState({ skip, limit }, () => {
        runGetPosts(skip, limit).then(res => {
          this.setState({ posts: res.posts });
        });
      });
    }
  }

  renderPosts() {
    return this.state.posts.map((post, index) => {
      return (
        <Col xs='12' key={'post-cell-' + index}>
          <div className='post-cell' style={this.props.currentPage > 1 && index === 0 ? { paddingTop: '0px', borderTop: 'none' } : {}}>
            <Row>
              <Col xs='12' sm='12' md='8'>
                <div style={{ fontSize: '24px', fontWeight: 'bold', paddingBottom: '12px' }}>{post.title}</div>
                <div style={{ color: '#3b4145', paddingBottom: '16px' }}>{post.contentPreview}</div>
                <div style={{ fontWeight: 'bold' }}>{post.author}</div>
                <div style={{ paddingBottom: '12px' }}>{moment(post.date).format('LL') + ' · ' + post.readTime}</div>
                <div><Link to={'/blog/articles/' + post.url}>Read More</Link></div>
              </Col>
              <Col xs='4' className='postlist-image'>
                <img src={post.thumbnailImage} alt={post.title} className='img-fluid' />
              </Col>
            </Row>
          </div>
        </Col>
      );  
    })
  }

  render() {
    if (this.props.numberOfPosts > 0 && this.props.numberOfPosts - this.props.resultsPerPage * this.props.currentPage <= -1 * this.props.resultsPerPage) {
      return <Redirect to='/blog/1' />
    }

    return (
      <Row className="PostList">
        {this.renderPosts()}
      </Row>
    );
  }
}

export default PostList;

const runGetPosts = async (skip, limit) => {
  const response = await fetch('/api/v1/posts/list?skip=' + skip + '&limit=' + limit, {
    method: "GET",
  }).then(response => {
    return response
  }, function(error) {
    console.log(error);
  });

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
}
