import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import salert from '../../modules/salert';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class RequestDemoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: '',
      name: '',
      organization: '',
      comments: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    const component = this;
    const { emailAddress, name, organization, comments } = this.state;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "counselor-demo-request", emailAddress, name, organization, comments })
    }).then(() => {
      component.setState({
        emailAddress: '',
        name: '',
        organization: '',
        comments: ''
      });

      salert('info', 'Thank you for your request, we will be reaching out shortly!')
      this.props.toggle();
    }).catch(error => alert(error));

    e.preventDefault();
  }

  render() {
    var { isOpen, toggle } = this.props;
    var { name, emailAddress, organization, comments } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size='lg'>
        {/* <form onSubmit={this.handleSubmit} id='counselor-demo-request'> */}
          <ModalHeader toggle={toggle}>Request a Demo or Register for a Webinar</ModalHeader>
          <ModalBody style={{ padding: '0px' }}>
            {/* <p>Send us a message to let us know when you are available for a demo. Our team will contact you as soon as we receive your message.</p> */}
            {/* <FormGroup>
              <Label for="name">Name</Label>
              <Input placeholder='Name' value={name} onChange={e => this.setState({ name: e.target.value })} required />
            </FormGroup>
            <FormGroup>
              <Label for="emailAddress">Email Address</Label>
              <Input placeholder='Email Address' value={emailAddress} onChange={e => this.setState({ emailAddress: e.target.value })} required />
            </FormGroup>
            <FormGroup>
              <Label for="organization">Organization</Label>
              <Input placeholder='Organization' value={organization} onChange={e => this.setState({ organization: e.target.value })} required />
            </FormGroup>
            <FormGroup>
              <Label for="comments">Availability/Comments</Label>
              <Input placeholder='Add a comment here...' type='textarea' value={comments} onChange={e => this.setState({ comments: e.target.value })} />
            </FormGroup> */}
            <iframe className="calendly-inline-widget" src="https://calendly.com/collegelab" style={{ width: '100%', height: '580px' }} />
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" type="submit">Submit</Button>{' '} */}
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        {/* </form> */}
      </Modal>
    );
  }
}

export default RequestDemoModal;
