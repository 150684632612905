import React, { Component } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container, Col, Row, Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';

import settings from '../../settings';

import ReCAPTCHA from "react-google-recaptcha";
import salert from '../../modules/salert';

var SHA256 = require("crypto-js/sha256");

class FreeTrialForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consent: false
    }

    this.recaptchaRef = React.createRef();
  }

  render() {
    return (
      <Container>
        <Row>
          <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} style={{ textAlign: 'center' }} className='header-col'>
            <p style={{ marginBottom: '45px', fontSize: '21px' }}><strong>Find</strong> schools that are the best fit for you, <strong>improve your chance</strong> of acceptance, <strong>save time and money</strong> by curating a well-balanced list.</p>
            <LinkContainer to='/signup' style={{ paddingTop: '24px', paddingBottom: '24px' }}>
              <Button size='lg' color='primary' block>
                Sign Up
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    )

    return (
      <Row className='FreeTrialForm'>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            _id: this.props._id
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (!this.state.consent) {
              setSubmitting(false);
              salert('error', "You must agree to CollegeLab's Terms of Service and Privacy Policy to create an account");
              return;
            }
            
            postSignup({
              user: {
                email: values.email,
                password: {
                  digest: SHA256(values.password).toString(),
                  algorithm: "sha-256"
                },
                plan: 'free-trial',
                profile: {
                  name: {
                    first: values.firstName,
                    last: values.lastName
                  }
                }
              }
            }).then(res => {
              if (res.err) {
                setSubmitting(false);
                salert('error', res.err);
                return;
              } else {
                window.location.replace(settings.APP_URL + '/login/' + res.token);
              }
            });
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().required('Required').test('len', 'Must be at least 6 characters', val => (val === undefined || (val && val.length >= 6))),
          })}
          >
          {({ 
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <form onSubmit={(e) => {
              e.preventDefault();

              if (process.env.NODE_ENV === 'production') this.recaptchaRef.current.execute();

              handleSubmit();
            }} id={values._id}>
              <Row>
                {!this.props.hideTitle && <Col xs='12' style={{ textAlign: 'center' }} className='header-col'>
                  <h4>Students, Start Your Trial Now</h4>
                  <p>Try all CollegeLab has to offer free for two weeks.</p>
                </Col>}
                <Col xs='12' className='trial-body'>
                  <Row>
                    <Col xs='12' sm='12' md='6'>
                      <FormGroup>
                        <Label>First Name</Label>
                        <Input
                          name="firstName"
                          type="text"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.firstName && touched.firstName}
                          placeholder='First Name'
                        />
                        {errors.firstName &&
                          touched.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
                      </FormGroup>
                    </Col>
                    <Col xs='12' sm='12' md='6'>
                      <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                          name="lastName"
                          type="text"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.lastName && touched.lastName}
                          placeholder='Last Name'
                        />
                        {errors.lastName &&
                          touched.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
                      </FormGroup>
                    </Col>
                    <Col xs='12'>
                      <FormGroup>
                        <Label>Email Address</Label>
                        <Input
                          name="email"
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.email && touched.email}
                          placeholder='Email Address'
                        />
                        {errors.email &&
                          touched.email && <FormFeedback>{errors.email}</FormFeedback>}
                      </FormGroup>
                    </Col>
                    <Col xs='12'>
                      <FormGroup>
                        <Label>Password</Label>
                        <Input
                          name="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={errors.password && touched.password}
                          placeholder='Password'
                        />
                        {errors.password &&
                          touched.password && <FormFeedback>{errors.password}</FormFeedback>}
                      </FormGroup>
                    </Col>
                    <Col xs='12' style={{ paddingTop: '8px' }}>
                      <FormGroup check>
                        <Label check className='custom-control custom-checkbox'>
                          <Input type="checkbox" readOnly checked={ this.state.consent } onClick={() => this.setState({ consent: !this.state.consent })} className='custom-control-input'/>{' '}
                            <span className="custom-control-indicator" ></span>
                            <span className="custom-control-description" style={{ cursor: "pointer", fontWeight: 'normal' }}>I am <strong>16 years of age or older</strong> and have <strong>read</strong> and <strong>agree</strong> to CollegeLab's <a href='https://app.collegelab.org/terms' target='_blank' rel="noopener noreferrer">Terms of Service</a> and <a href='https://app.collegelab.org/privacy' target='_blank' rel="noopener noreferrer">Privacy Policy.</a></span>
                          </Label>
                      </FormGroup>
                    </Col>
                    <Col xs='12' style={{ textAlign: 'center' }}>
                      <Button type='submit' color={this.props.buttonColor ? this.props.buttonColor : 'info'}  size='lg' style={{ width: '200px', marginTop: '16px' }}>
                        {isSubmitting ? <FontAwesome name='spinner' spin /> : 'Create Account'}
                      </Button>
                    </Col>
                    <Col xs='12' style={{ paddingTop: '16px', textAlign: 'center' }}>
                      {process.env.NODE_ENV === 'production' &&
                        <ReCAPTCHA
                          ref={this.recaptchaRef}
                          size="invisible"
                          badge="inline"
                          sitekey={settings.RECAPTCHA}
                        />
                      }
                      <div>
                        <small>This site is protected by reCAPTCHA and the Google 
                          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                          <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                        </small>
                      </div>
                    </Col>
                    {/* <Col xs='12' style={{ textAlign: 'center', marginTop: '24px' }}>
                      <small>By creating an account you agree to CollegeLab <a href='https://app.cyberbuoy.io/terms'>Terms of Service</a> and <a href='https://app.cyberbuoy.io/privacy'>Privacy Policy</a></small>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </Row>
    )
  }
}

export default FreeTrialForm;

const postSignup = async (user) => {
  const response = await fetch('/api/v1/individual-signup', {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => {
    return response
  }, function(error) {
    console.log(error);
  });

  const body = await response.json();
  return body;
}