import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import pako from 'pako';
import { createLunrDocument } from './modules/lunr';

ReactDOM.render(<App />, document.getElementById('root'));

const setupSearch = async () => {
  const res = await fetch('/cl_p.txt');
  let body = await res.arrayBuffer();

  let colleges = JSON.parse(pako.inflate(body, { to: 'string' }));
  let fields = [
    { name: 'name', attributes: { boost: 2 }},
    { name: 'state' },
    { name: 'conference' },
  ]

  // merge all the nickname fields into a flat document so we can use them in search
  const flatDocument = colleges.map(college => {
    let searchableDoc = {
      _id: college._id,
      name: college.name,
      conference: college.conference
    }

    college.filters.forEach((filter, i) => {
      if (filter.type == 'nickname') {
        searchableDoc[`nickname-${i}`] = filter.value;

        if (fields.findIndex(field => field.name == `nickname-${i}`) == -1) {
          fields.push({ name: `nickname-${i}`, attributes: { boost: 10 }});
        }
      }

      if (filter.type == 'state') {
        searchableDoc.state = filter.label;
      }
    });

    return searchableDoc;
  });

  createLunrDocument(flatDocument, fields, 'collegeIdx');
  window.idx.map = colleges.map(college => ({ _id: college._id, location: college.location, displayName: college.displayName }));
}

setupSearch();
