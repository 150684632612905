import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Navigation from './components/Navigation/Navigation';
import Index from './pages/Index/Index';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Counselors from './pages/Counselors/Counselors';
// import Faqs from './pages/Faqs/Faqs';
import NotFound from './pages/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import IndividualSignup from './pages/IndividualSignup/IndividualSignup';
import Updater from './components/Updater/Updater';
//import Pricing from './pages/Pricing/Pricing';
import Report from './pages/Report/Report';

import { ToastContainer } from 'react-toastify';

import settings from './settings';

import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import './App.scss';
import Blog from './pages/Blog/Blog';

class App extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <div className="App">
            <Navigation {...this.props} />
            <ScrollToTop />
            <div id='content-main'>
              <Switch>
                <Route exact name="index" path="/" component={Index} />
                <Route exact name="about" path="/about" component={About} />
                <Route exact name="blog2" path="/blog" component={Blog} />
                <Route exact name="contact" path="/contact" component={Contact} />
                <Route exact name="counselors" path="/counselors" component={Counselors} />
                {/* <Route exact name="guided-path" path="/guided-path-transition-offer" component={GuidedPath} /> */}
                <Route exact path='/signup' component={() => {
                  return (
                    <IndividualSignup />
                  )
                }} />
                <Route exact path='/signup/:token' component={() => {
                  return (
                    <IndividualSignup />
                  )
                }} />
                <Route path='/login' component={() => window.location.href = settings.APP_URL + '/login'} />
                <Route path='/tos' component={() => window.location.href = settings.APP_URL + '/tos'} />
                <Route path='/privacy' component={() => window.location.href = settings.APP_URL + '/privacy'} />
                {/* <Route name='blog' exact path='/blog' component={() => <Redirect to='https://medium.com/collegelab' target='_blank' />} />
                  <Route name='blog-page' exact path='/blog/:page' component={() => window.location.href = 'https://medium.com/collegelab'} />
                  <Route name='blog-page' exact path='/blog/:page' component={() => window.location.href = 'https://medium.com/collegelab'} />
                  <Route exact name="blog-article" path="/blog/articles/:article" component={() => window.location.href = 'https://medium.com/collegelab'} /> */}
                {/* <Route exact name="college" path="/colleges/:slug" component={CollegeWrapper} /> */}
                {/*<Route exact name="pricing" path="/pricing" component={Pricing} />*/}
                <Route exact name="report" path="/v1/reports" component={Report} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <Footer />
            <Updater />
          </div>
        </Router>
        <ToastContainer
          className='toast-container'
          position="bottom-right"
          autoClose={2500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOn />
      </div>
    );
  }
}

export default App;
