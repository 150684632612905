/* eslint-disable max-len */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import moment from 'moment';

import './Blog.scss';

class Featured extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    runGetFeatured().then(res => {
      this.setState({
        posts: res.posts
      })
    })
  }

  renderFeaturedPosts() {
    return this.state.posts.map((post, index) => {
      if (index < 2) {
        return (
          <Col xs='12' sm='12' md='6'  key={'post' + index}>
            <div className='featured-content'>
              <div className='featured-thumbnail'><img alt={post.title} src={post.thumbnailImage} width='100%' /></div>
              <div className='featured-title'><Link to={'/blog/articles/' + post.url}>{post.title}</Link></div>
              <div className='featured-text'>{post.contentPreview}</div>
              <Row noGutters style={{ paddingLeft: '24px', paddingRight: '24px', fontSize: '16px', color: '#3b4145', marginBottom: '24px' }}>
                <Col xs='auto'><img alt={post.author} height='52px' width='52px' src={post.authorImage} style={{ borderRadius: '50%', marginRight: '14px' }}/></Col>
                <Col xs='auto'>
                  <div style={{ fontWeight: 'bold' }}>{post.author}</div>
                  <div>{moment(post.date).format('LL') + ' · ' + post.readTime}</div>
                </Col>
              </Row>
            </div>
          </Col>
        );
      } else {
        return null;
      }
    })
  } 

  render() {
    return (
      <div className="Featured">
        <Row>
          {this.renderFeaturedPosts()}
        </Row>
      </div>
    );
  }
}

export default Featured;

const runGetFeatured = async () => {
  const response = await fetch('/api/v1/posts/featured', {
    method: "GET"
  }).then(response => {
    return response
  }, function(error) {
    console.log(error);
  });

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);

  return body;
}