import { React, Component } from 'react';
import { withRouter } from 'react-router-dom';

import lozad from 'lozad'

var observer = null;

class HandleUpdate extends Component {
  componentDidMount() {
    observer = lozad('.lozad'); // lazy loads elements with default selector as '.lozad'
    observer.observe();

    var elements = document.querySelectorAll('.lozad');
    elements.forEach(element => {
      observer.triggerLoad(element);
    });
  }

  componentDidUpdate() {
    observer.observe();

    var elements = document.querySelectorAll('.lozad');
    elements.forEach(element => {
      observer.triggerLoad(element);
    });
  }

  componentWillUpdate(nextProps) {
    // listen to route changes
    // if an update is available, reload the page on a route change
    if (nextProps.location.pathname && nextProps.location.pathname != this.props.location.pathname ) {
      var isUpdateAvailable = sessionStorage.getItem('isUpdateAvailable');

      if (isUpdateAvailable == 'true') {
        window.location.reload();
      }
    }
  }

  render() {
    return null;
  }
}

export default withRouter(HandleUpdate);